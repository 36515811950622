// Timed Text Markup Language (TTML).
// See http://www.w3.org/TR/ttml1/ for more details.

/**
 * TtmlTimeParser class.
 * @classdesc - The TtmlTimeParser class is used to parse ttml time expressions which can be absolute or relative.
 * @export
 */
export class TtmlTimeParser {
    /**
     * @name - absoluteTimeRegex
     * @description - Regex to parse absolute ttml times. (clock-time)
     *                hours ":" minutes ":" seconds ( fraction | ":" frames ( "." sub-frames )? )?
     *                NOTE: (johnlemi)  Our clocktime regex matches 1 or 2 digits for hours instead of 2 because even
     *                though the spec says 2 we have some existing ttml from the Office team with end attrs with 1 hour digit.
     * @static
     * @private
     * @type {string}
     */
    private static readonly absoluteTimeRegex: RegExp = /^(\d+):(\d{2}):(\d{2})((\.\d+)|:(\d{2,}(\.\d+)?))?$/;

    /**
     * @name - relativeTimeRegex
     * @description - Regex to parse relative ttml times. (offset-time)
     *                time-count fraction? metric.
     * @static
     * @private
     * @type {string}
     */
    private static readonly relativeTimeRegex: RegExp = /^(\d+(\.\d+)?)(ms|[fhmst])$/;

    /**
     * @name - mediaFrameRate.
     * @description - mediaFrameRate.
     * @private
     * @type {string}
     */
    private readonly mediaFrameRate: number;

    /**
     * @name - mediaTickRate.
     * @description - mediaTickRate.
     * @private
     * @type {string}
     */
    private readonly mediaTickRate: number;

    /**
     * @class
     * @description - Constructor for the TtmlTimeParser class.
     * @param _mediaFrameRate
     * @param _mediaTickRate
     * @public
     * @param {number} mediaFrameRate - The ttml media frame rate.
     * @param {number} mediaTickRate - The ttml media tick rate.
     */
    constructor(_mediaFrameRate: number, _mediaTickRate: number) {
        this.mediaFrameRate = _mediaFrameRate;
        this.mediaTickRate = _mediaTickRate;
    }

    /**
     * @name - parse
     * @description - Parses a ttml time expression string into a number.
     * @public
     * @param {string} ttmlTime - The time expression to parse.
     * @returns {number} - The number the time expression represents, or 0 if the exression doesn't match.
     */
    public parse(ttmlTime: string): number {
        if (!ttmlTime) {
            return 0;
        }

        const absoluteTime = TtmlTimeParser.absoluteTimeRegex.exec(ttmlTime);

        if (absoluteTime && (absoluteTime.length > 3)) {
            const hours = Number.parseInt(absoluteTime[1], 10) * 60 * 60;
            const minutes = Number.parseInt(absoluteTime[2], 10) * 60;
            const seconds = Number.parseInt(absoluteTime[3], 10);
            let subseconds = 0;

            if (absoluteTime[5]) {
                subseconds = Number.parseFloat(absoluteTime[4]) * 1000;
            }

            if (absoluteTime[6]) {
                subseconds = Math.round(Number.parseFloat(absoluteTime[6]) * this._getTimeUnitMultiplier('f'));
            }

            return ((hours + minutes + seconds) * 1000) + subseconds;
        }

        const relativeTime = TtmlTimeParser.relativeTimeRegex.exec(ttmlTime);

        if (relativeTime && (relativeTime.length > 3)) {
            return Math.round(Number.parseFloat(relativeTime[1]) * this._getTimeUnitMultiplier(relativeTime[3]));
        }

        return 0;
    }

    /**
     * @name - getTimeUnitMultiplier
     * @description - Converts a ttml time unit expression string into a multiplier number.
     * @private
     * @param {string} timeUnit - The time unit expression.
     * @returns {number} - The multiplier value, or 0 if the unit exression doesn't match.
     */
    private _getTimeUnitMultiplier(timeUnit: string): number {
        switch (timeUnit) {
            case 'h':
                return 1000 * 60 * 60;
            case 'ms':
                return 1;
            case 'm':
                return 1000 * 60;
            case 's':
                return 1000;
            case 'f':
                return 1000 / this.mediaFrameRate;
            case 't':
                return 1000 / this.mediaTickRate;
            default:
                return 0;
        }
    }
}
