import {
    BaseNotificationComponent, ClosableNotificationWrapper,
    DismissibleNotificationWrapper, INotificationCloseButtonProps, INotificationProps, NotificationCloseButton,
    NotificationContent, NotificationFooter, NotificationHeader, NotificationInstance, NotificationTitle
} from '@msdyn365-commerce-modules/notifications-core';
import { getFallbackImageUrl, StringExtensions } from '@msdyn365-commerce-modules/retail-actions';
import { Button, getPayloadObject, getTelemetryAttributes, TelemetryConstant } from '@msdyn365-commerce-modules/utilities';
import { Image } from '@msdyn365-commerce/core';
import React from 'react';
import { PriceComponent } from '../../price/price.components';
import { ItemSuccessfullyAddedToCartNotification } from './item-successfully-added-to-cart-notification';

export interface IItemSuccessfullyAddedToCartNotificationProps extends INotificationProps<
    ItemSuccessfullyAddedToCartNotification>, INotificationCloseButtonProps<ItemSuccessfullyAddedToCartNotification> {}

// tslint:disable-next-line:completed-docs
export class ItemSuccessfullyAddedToCartNotificationComponent extends BaseNotificationComponent<ItemSuccessfullyAddedToCartNotification, IItemSuccessfullyAddedToCartNotificationProps> {
    public static readonly className: string = 'msc-notification-item-successfully-added-to-cart';

    public constructor(props: IItemSuccessfullyAddedToCartNotificationProps) {
        super(props);

        this._close = this._close.bind(this);
    }

    // tslint:disable-next-line:max-func-body-length
    public render(): React.ReactNode {
        const payLoad = getPayloadObject('click', this.props.notification.telemetryContent, TelemetryConstant.ContinueShopping);
        payLoad.contentAction.etext = TelemetryConstant.ViewBagAndCheckout;
        const viewBagAttribute = getTelemetryAttributes(this.props.notification.telemetryContent, payLoad);
        const fallbackImageUrl = getFallbackImageUrl(this.props.notification.product.ItemId, this.props.notification.context.actionContext.requestContext.apiSettings);
        if (this.props.notification.imageSettings) {
            this.props.notification.imageSettings.cropFocalRegion = true;
        }

        const reducedDimensions: string = this.props.notification.product.Dimensions ? this.props.notification.product.Dimensions.reduce<string>(
            (str, productDimension) => {
                if (productDimension.DimensionValue && productDimension.DimensionValue.Value) {
                    if (str) {
                        return `${str}, ${productDimension.DimensionValue.Value}`;
                    }

                    return `${productDimension.DimensionValue.Value}`;
                }

                return str;
            },
            '') : '';

        const contentClassName = NotificationContent.className;
        const footerClassName = NotificationFooter.className;

        return (
            <div className={ItemSuccessfullyAddedToCartNotificationComponent.className}>
                <ClosableNotificationWrapper
                    notification={this.props.notification}
                >
                    <DismissibleNotificationWrapper
                        notification={this.props.notification}
                    >
                        <NotificationInstance
                            notification={this.props.notification}
                        >
                            <NotificationHeader
                                notification={this.props.notification}
                            >
                                <NotificationTitle
                                    notification={this.props.notification}
                                />
                                <NotificationCloseButton
                                    notification={this.props.notification}
                                />
                            </NotificationHeader>
                            <NotificationContent
                                notification={this.props.notification}
                            >
                                <Image
                                    className={`${contentClassName}__image`}
                                    src={this.props.notification.product.PrimaryImageUrl || ''}
                                    fallBackSrc={fallbackImageUrl}
                                    altText={this.props.notification.product.Name}
                                    gridSettings={this.props.notification.gridSettings || {}}
                                    imageSettings={this.props.notification.imageSettings}
                                    loadFailureBehavior='empty'
                                />
                                <div className={`${contentClassName}__product-info`}>
                                    <div className={`${contentClassName}__product-title`}>
                                        {this.props.notification.product.Name}
                                    </div>
                                    {
                                        this.props.notification.price ? (
                                            <div className={`${contentClassName}__product-price`}>
                                                <PriceComponent
                                                    data={{ price: this.props.notification.price }}
                                                    context={this.props.notification.context}
                                                    id={this.props.notification.id}
                                                    typeName={this.props.notification.typeName}
                                                    freePriceText={this.props.notification.resources.freePriceText}
                                                    originalPriceText={this.props.notification.resources.originalPriceText}
                                                    currentPriceText={this.props.notification.resources.currentPriceText}
                                                />
                                            </div>
                                        ) : ''
                                    }
                                    {
                                        StringExtensions.isNullOrWhitespace(reducedDimensions) ? null : (
                                            <div className={`${contentClassName}__product-variants`}>
                                                {reducedDimensions}
                                            </div>
                                        )
                                    }
                                    <div className={`${contentClassName}__product-quantity`}>
                                        {this.props.notification.resources.addedQuantityText.replace('{0}', this.props.notification.productQuantity.toString())}
                                    </div>
                                </div>
                            </NotificationContent>
                            <NotificationFooter
                                notification={this.props.notification}
                            >
                                {
                                    this.props.notification.navigationUrl && (
                                        <Button
                                            className={`${footerClassName}__go-to-cart`}
                                            href={this.props.notification.navigationUrl}
                                            title={this.props.notification.resources.goToCartText}
                                            {...viewBagAttribute}
                                        >
                                            {this.props.notification.resources.goToCartText}
                                        </Button>
                                    )
                                }
                            </NotificationFooter>
                        </NotificationInstance>
                    </DismissibleNotificationWrapper>
                </ClosableNotificationWrapper>
            </div>
        );
    }

    private _close(): void {
        this.props.notification.close();
    }
}
