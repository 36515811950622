// eslint-disable-next-line unicorn/filename-case
import * as React from 'react';

interface CarouselContext {
    direction?: string;
}

// Create a context wrapping some shared state
export default React.createContext<CarouselContext>({
    direction: 'right'
});
