import * as React from 'react';
import MenuItem from '../menu-item/menu-item';
import { IMenuItemProps } from '../menu-item/menu-itemProps';
import { IMenuProps } from './menuProps';

/**
 *
 * Menu React component for video player.
 *
 */
export default class Menu extends React.Component<IMenuProps> {
    private readonly target: React.RefObject<HTMLDivElement> = React.createRef<HTMLDivElement>();

    constructor(props: IMenuProps) {
        super(props);
        this.handleDocumentClick = this.handleDocumentClick.bind(this);
        this._onKeyDown = this._onKeyDown.bind(this);
        this._onKeyUp = this._onKeyUp.bind(this);
    }

    public componentDidMount(): void {
        ['click', 'touchstart'].forEach((event: string) => {
            window && window.addEventListener(event, this.handleDocumentClick, true);
        });
    }

    public componentWillUnmount(): void {
        ['click', 'touchstart'].forEach((event: string) => {
            window && window.removeEventListener(event, this.handleDocumentClick, true);
        });
    }

    public getMenuWidth(): number {
        return this.target.current!.offsetWidth;
    }

    public shouldComponentUpdate(nextProps: IMenuProps): boolean {
        let shouldRender = false;
        if (nextProps.playerMenu !== this.props.playerMenu) {
            shouldRender = true;
        }
        return shouldRender;
    }

    public render(): JSX.Element {
        const { className, style } = this.props;
        const menuItems: IMenuItemProps[] = this.props.playerMenu.items;
        const count: number = menuItems.length;
        return (
            <div
                className={className} style={style} ref={this.target}
                data-category={this.props.playerMenu.category}>
                <ul
                    role='menu' className={`${className}__list`} onKeyDown={this._onKeyDown}
                    onKeyUp={this._onKeyUp} >
                    {menuItems.map(
                        (menuItem: IMenuItemProps, index: number) => {
                            return (
                                <MenuItem
                                    id={menuItem.id}
                                    key={index}
                                    label={menuItem.label}
                                    className={`${className}__menuitem`}
                                    language={menuItem.language}
                                    data={menuItem.data}
                                    isBackButton={menuItem.isBackButton}
                                    image={menuItem.image}
                                    imageAlt={menuItem.imageAlt}
                                    itemCount={count}
                                    itemIndex={index}
                                    glyph={menuItem.glyph}
                                    selected={menuItem.selected}
                                    selectable={menuItem.selectable}
                                    persistOnClick={menuItem.persistOnClick}
                                    {...(menuItem.id.includes('cc-preset')) ? {} : { ariaLabel: menuItem.ariaLabel }}
                                    subMenu={menuItem.subMenu}
                                    hasMouseHoverEvents={menuItem.hasMouseHoverEvents}
                                    hasChildren={!!(menuItem.subMenu !== undefined && menuItem.subMenu.items && menuItem.subMenu.items.length > 0)}
                                    onClick={this.props.onMenuItemClick}
                                    onFocus={this.props.onFocus}
                                    onBlur={this.props.onBlur}
                                    onKeyDown={this.props.onKeyDown}
                                    onKeyUp={this.props.onKeyUp}
                                    onMouseOver={this.props.onMouseOver}
                                    onMouseOut={this.props.onMouseOut}
                                    hasFocus={menuItem.hasFocus}
                                />
                            );
                        }
                    )}
                </ul>
            </div>
        );
    }

    public handleDocumentClick(e: Event): void {
        const target = e.target as HTMLElement;
        if (this.target !== undefined && !(this.target.current !== null && (e.target === this.target.current || this.target.current.contains(target)))) {
            this.props.onDocumentClick(e);
        }
    }

    private _onKeyDown(event: React.KeyboardEvent<HTMLUListElement>): void {
        this.props.onKeyDown!(event as unknown as Event);
    }

    private _onKeyUp(event: React.KeyboardEvent<HTMLUListElement>): void {
        this.props.onKeyUp!(event as unknown as Event);
    }

}
