/**
 * @name - window
 * @description - typescript declaration for window object.
 * @type {object}
 */
// tslint:disable-next-line: no-any
declare let window: any;

/**
 * @name - XDomainRequest
 * @description - typescript declaration for XDomainRequest object.
 * @type {object}
 */
// tslint:disable-next-line: no-any
declare let XDomainRequest: any;

/**
 * PlayerUtilities class.
 * @classdesc - The PlayerOptions class is used to pass player options settings to the player.
 *              It supplies default values which can be overridden via overrides passed to the constructor.
 * @export
 */
// tslint:disable-next-line: no-unnecessary-class
export class PlayerUtility {

    /**
     * @name - pendingAjaxRequests
     * @description -  array to hold pending ajax requests.
     * @private
     * @static
     * @returns {any}
     */
    // tslint:disable-next-line: no-any
    private static readonly pendingAjaxRequests: any = [];

    /**
     * @name - ajax
     * @description -  Gets request data using ajax call.
     * @private
     * @static
     * @param {string} url - Url from where to fetch data.
     * @param {Function} done - On success callback function.
     * @param {Function} failed - On failed callback function.
     * @returns {void}
     */
    public static ajax(url: string, done: Function, failed?: Function): void {
        if (!url) {
            return;
        }

        // tslint:disable-next-line: no-any
        let ajaxRequest: any = null;

        // For IE 9 and below use XDomainRequest
        if (window.XDomainRequest) {
            ajaxRequest = new XDomainRequest();

            ajaxRequest.addEventListener('load', () => {
                done && done(ajaxRequest.responseText);
                PlayerUtility._removeFromPendingAjaxRequests(ajaxRequest);
            });

            // IE8 and IE9 have a bug where garbage collector will clear XDomainRequest object
            // before the call is completed. Thus add a reference to the object so that request
            // is not aborted by garbage collector.
            PlayerUtility.pendingAjaxRequests.push(ajaxRequest);
        } else if (window.XMLHttpRequest) {
            ajaxRequest = new XMLHttpRequest();

            ajaxRequest.onreadystatechange = () => {
                if (ajaxRequest.readyState === 4) {
                // tslint:disable-next-line: no-any
                    let result: any = null;
                    if (ajaxRequest.status === 200) {
                        result = ajaxRequest.responseText;
                    }
                    done && done(result);
                } else {
                // The following console.log useful for debugging and is left in commented out for easy restoration.
                // console.log('Localization-helper: readystate(' + xhttp.readyState + ') status(' + xhttp.status + ')');
                }
            };
        }

        // Attach common event handlers and make the request.
        if (ajaxRequest) {
            ajaxRequest.ontimeout = ajaxRequest.addEventListener('error', () => {
                PlayerUtility._removeFromPendingAjaxRequests(ajaxRequest);
                failed && failed();
            });
            ajaxRequest.open('GET', url, true);
            ajaxRequest.send();
        }
    }

    /**
     * @param obj
     * @name - removeFromPendingAjaxRequests
     * @description - removes object from array to hold pending ajax requests.
     * @private
     * @static
     * @returns {any}
     */
    // tslint:disable-next-line: no-any
    private static _removeFromPendingAjaxRequests(obj: any): void {
    // When the call is completed remove it from the array
        let index = -1;
        for (let i = 0; i < PlayerUtility.pendingAjaxRequests.length; i++) {
            if (obj === PlayerUtility.pendingAjaxRequests[i]) {
                index = i;
                break;
            }
        }
        if (index >= 0) {
            PlayerUtility.pendingAjaxRequests.splice(index, 1);
        }
    }
}
