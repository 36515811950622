import { IActionContext } from '@msdyn365-commerce/core';
import { addInvoicesAsync } from '@msdyn365-commerce/retail-proxy/dist/DataActions/CartsDataActions.g';
import { Cart } from '@msdyn365-commerce/retail-proxy/dist/Entities/CommerceTypes.g';
import { ICartActionResultWithCart } from './cart-action-result';

export default async function addInvoicesToCartInternal(cart: Readonly<Cart>, invoiceIds: string[], actionContext: IActionContext): Promise<ICartActionResultWithCart> {
    if (!invoiceIds || invoiceIds.length === 0) {
        actionContext.telemetry.trace('Invoice ids are empty, unable to add invoices to cart.');
        return { cart: cart, status: 'FAILED' };
    }

    if (cart.Version) {
        return addInvoicesAsync({ callerContext: actionContext }, cart.Id, invoiceIds)
        .then<ICartActionResultWithCart>(newCart => {
            return <ICartActionResultWithCart> { cart: newCart, status: 'SUCCESS' };
        })
        .catch(error => {
            actionContext.telemetry.trace(error);
            actionContext.telemetry.trace('Unable to add invoices to cart');

            return <ICartActionResultWithCart> { cart: undefined, status: 'FAILED', errorDetails: { LocalizedMessage: error.message } };
        });
    } else {
        actionContext.telemetry.warning('Unable to add invoices, Cart Version could not be found');
    }

    return <ICartActionResultWithCart> { cart: undefined, status: 'FAILED' };
}