
import { IAny, ICoreContext, IGeneric, IGridSettings, IImageSettings, RichText } from '@msdyn365-commerce/core';

import { DismissibleNotification, IClosableNotification, ITitledNotification, NotificationEvents, withNotificationComponent } from '@msdyn365-commerce-modules/notifications-core';
import { Event } from '@msdyn365-commerce-modules/retail-actions';
import { ITelemetryContent } from '@msdyn365-commerce-modules/utilities';
import { ProductPrice, SimpleProduct } from '@msdyn365-commerce/retail-proxy';

import { IAddToCartResources } from '../add-to-cart.component';
import { ItemSuccessfullyAddedToCartNotificationComponent } from './item-successfully-added-to-cart-notification-component';

/**
 * Notification which will be shown after the user adds an item to the cart.
 */
@withNotificationComponent(ItemSuccessfullyAddedToCartNotificationComponent)
export class ItemSuccessfullyAddedToCartNotification extends DismissibleNotification implements ITitledNotification, IClosableNotification {
    public readonly context: ICoreContext<IGeneric<IAny>>;

    public readonly resources: IAddToCartResources;

    public readonly imageSettings?: IImageSettings;

    public readonly gridSettings?: IGridSettings;

    public readonly product: SimpleProduct;

    public readonly price?: ProductPrice;

    public readonly productQuantity: number;

    public readonly navigationUrl?: string;

    public readonly telemetryContent: ITelemetryContent;

    public readonly closeAriaLabel: string;

    public readonly title: RichText;

    public readonly componentId: string;

    public readonly typeName: string;

    public isClosed: boolean;

    public constructor(
        context: ICoreContext<IGeneric<IAny>>,
        resources: IAddToCartResources,
        imageSettings: IImageSettings | undefined,
        gridSettings: IGridSettings | undefined,
        product: SimpleProduct,
        price: ProductPrice | undefined,
        productQuantity: number,
        navigationUrl: string | undefined,
        telemetryContent: ITelemetryContent,
        componentId: string,
        typeName: string
    ) {
        super();

        const itemsString = productQuantity === 1 ? resources.headerItemOneText : resources.headerItemFormatText.replace('{0}', productQuantity.toString());

        this.title = `<b>${itemsString}</b><span>${resources.headerMessageText}</span>`;

        this.context = context;
        this.resources = resources;
        this.imageSettings = imageSettings;
        this.gridSettings = gridSettings;
        this.product = product;
        this.price = price;
        this.productQuantity = productQuantity;
        this.navigationUrl = navigationUrl;
        this.telemetryContent = telemetryContent;
        this.componentId = componentId;
        this.typeName = typeName;

        this.isClosed = false;
        this.closeAriaLabel = resources.continueShoppingText;

        this.events.setValue(NotificationEvents.Close, new Event());
    }

    public close(): void {
        this.isClosed = true;
        this.events.getValue(NotificationEvents.Close)?.trigger();
    }
}
