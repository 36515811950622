import { getFallbackImageUrl } from '@msdyn365-commerce-modules/retail-actions';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from '@msdyn365-commerce-modules/utilities';
import { getUrlSync, IComponentProps, ICoreContext, IImageSettings, Image } from '@msdyn365-commerce/core';
import { ProductPrice, SimpleProduct } from '@msdyn365-commerce/retail-proxy';
import React from 'react';
import { PriceComponent } from '../../price/price.components';

export interface IItemAddedToOrderTemplateDialogResources {
    viewOrderTemplateButtonText: string;
    continueShoppingButtonText: string;
    itemAddedToOrderTemplateHeaderItemOneText: string;
    itemAddedToOrderTemplateHeaderItemFormatText: string;
    itemAddedToOrderTemplateHeaderMessageText: string;

    freePriceText: string;
    originalPriceText: string;
    currentPriceText: string;
}

export interface IItemAddedToOrderTemplateDialogData {
    product: SimpleProduct;
    productPrice: ProductPrice;
    quantity: number;
    orderTemplateId: string;
}

export interface IItemAddedToOrderTemplateDialogProps extends IComponentProps<IItemAddedToOrderTemplateDialogData> {
    className: string;

    dialogStrings: IItemAddedToOrderTemplateDialogResources;

    modalOpen: boolean | undefined;
}

const renderOrderTemplateImage = (
    context: ICoreContext, className: string, product: SimpleProduct, imageSettings?: IImageSettings) => {
    const fallbackImageUrl = getFallbackImageUrl(product.ItemId, context.actionContext.requestContext.apiSettings);
    if (imageSettings) {
        imageSettings.cropFocalRegion = true;
    }
    const defaultImageSettings: IImageSettings = {
        viewports: {
            xs: { q: 'w=140&h=140&m=6', w: 0, h: 0 },
            lg: { q: 'w=140&h=140&m=6', w: 0, h: 0 },
            xl: { q: 'w=140&h=140&m=6', w: 0, h: 0 }
        },
        lazyload: true,
        cropFocalRegion: true
    };
    return (
        <Image
            className={`${className}__image-props`}
            src={product.PrimaryImageUrl || ''}
            altText={product.Name}
            fallBackSrc={fallbackImageUrl}
            gridSettings={context.request.gridSettings!}
            imageSettings={imageSettings || defaultImageSettings}
            loadFailureBehavior='empty'
        />
    );
};

export const ItemAddedToOrderTemplateDialog = (dialogProps: IItemAddedToOrderTemplateDialogProps) => {
    const {
        dialogStrings,
        className,
        context,
        modalOpen,
        data: {
            orderTemplateId,
            product,
            productPrice,
            quantity
        }
    } = dialogProps;

    const getOrderTemplatePageUrl = (): string => {
        const url = getUrlSync('ordertemplate', context.actionContext) || '';
        const separator = url.includes('?') ? '&' : '?';
        return `${url}${separator}id=${orderTemplateId}`;
    };

    const close: () => void = () => {
        window.location.reload(true);
    };

    const itemsString = quantity === 1 ? dialogStrings.itemAddedToOrderTemplateHeaderItemOneText : dialogStrings.itemAddedToOrderTemplateHeaderItemFormatText.replace('{0}', quantity.toString());

    return (
        <Modal isOpen={modalOpen} className={`${className}`} toggle={close}>
            <ModalHeader className={`${className}__dialog__header`} toggle={close}>
                <b>
                    {itemsString}
                </b>
                <span>
                    {dialogStrings.itemAddedToOrderTemplateHeaderMessageText}
                </span>
            </ModalHeader>
            <ModalBody className={`${className}__dialog__body`}>
                <div className={`${className}__dialog__content`}>
                    {renderOrderTemplateImage(context, `${className}__dialog`, product)}
                    <div className={`${className}__dialog__description`}>
                        <div className={`${className}__dialog__product-name`}>
                            {product.Name}
                        </div>
                        <div className={`${className}__dialog__product-price`}>
                            {
                                productPrice && <PriceComponent
                                    data={{ price: productPrice }}
                                    context={dialogProps.context}
                                    id={dialogProps.id}
                                    typeName={dialogProps.typeName}
                                    freePriceText={dialogProps.dialogStrings.freePriceText}
                                    originalPriceText={dialogProps.dialogStrings.originalPriceText}
                                    currentPriceText={dialogProps.dialogStrings.currentPriceText}
                                />
                            }
                        </div>
                        {
                            product.Dimensions &&
                                <div className={`${className}__dialog__product-dimensions`}>
                                    {product.Dimensions.map(dimension => dimension.DimensionValue?.Value).filter(value => value).join(', ')}
                                </div>
                        }
                    </div>
                </div>
            </ModalBody>
            <ModalFooter
                className={`${className}__dialog__footer`}
            >
                <Button
                    className={`${className}__dialog__view-order-template-button`}
                    title={dialogStrings.viewOrderTemplateButtonText}
                    href={getOrderTemplatePageUrl()}
                >
                    {dialogStrings.viewOrderTemplateButtonText}
                </Button>
                <Button
                    className={`${className}__dialog__continue-shopping`}
                    title={dialogStrings.continueShoppingButtonText}
                    onClick={close}
                >
                    {dialogStrings.continueShoppingButtonText}
                </Button>
            </ModalFooter>
        </Modal>
    );
};
