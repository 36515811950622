import classnames from 'classnames';
import React, { useState } from 'react';

import { Button, getPayloadObject, getTelemetryAttributes, IPopupProductsProps, ITelemetryContent, PopupProducts, TelemetryConstant } from '@msdyn365-commerce-modules/utilities';
import { IComponent, IComponentProps, msdyn365Commerce } from '@msdyn365-commerce/core';

import { addOrderTemplateToCartAction, AddOrderTemplateToCartInput, OrderTemplate } from '@msdyn365-commerce-modules/retail-actions';

import { FullProduct } from '@msdyn365-commerce/commerce-entities';
import { ILinesAddedToCartDialogResources, LinesAddedToCartDialog } from '../lines-added-to-cart-dialog/lines-added-to-cart-dialog.component';

export interface IAddOrderTemplateToCartComponentData {
    orderTemplate: OrderTemplate;
}

export interface IAddOrderTemplateToCartComponentProps extends IComponentProps<IAddOrderTemplateToCartComponentData> {
    className?: string;
    addToCartText: string;
    telemetryContent?: ITelemetryContent;
    dialogStrings: {
        closeWindowButtonText: string;
        addToCartFailureMessage: string;
        addToCartSuccessMessage: string;
        addToCartProcessMessage: string;
        linesAddedToCartDialogResources: ILinesAddedToCartDialogResources;
    };
}

export declare type IAddToCartActionFailureReason = 'EMPTYINPUT' | 'MISSINGDIMENSION' | 'OUTOFSTOCK' | 'CARTACTIONFAILED'; // Rename to used method

export interface IAddOrderTemplateToCartComponent extends IComponent<IAddOrderTemplateToCartComponentProps> {
    onClick(): (event: React.MouseEvent<HTMLElement>, props: IAddOrderTemplateToCartComponentProps) => void;
}

const onClick = async (
    _event: React.MouseEvent<HTMLElement>,
    props: IAddOrderTemplateToCartComponentProps,
    setUploadStatus: (uploadStatus: addOrderTemplateToCartStatus) => void,
    openModal: (opened: boolean) => void,
    setLinesAddedToCartDialogOpen: (opened: boolean) => void,
    setAddedLinesCount: (value: number) => void): Promise<void> => {
    openModal(true);
    setUploadStatus('LOADING');

    const { context } = props;
    const addOrderTemplateToCartInput = new AddOrderTemplateToCartInput(props.data.orderTemplate.productList.Id);
    const addedCartLines = await addOrderTemplateToCartAction(addOrderTemplateToCartInput, context.actionContext);
    setAddedLinesCount(addedCartLines.length);
    openModal(false);
    setTimeout(() => {
        setLinesAddedToCartDialogOpen(true);
    }, 500); // tslint:disable-line:align
};

const AddToCartComponentActions = {
    onClick
};

export type addOrderTemplateToCartStatus = 'LOADING' | 'SUCCESS' | 'FAILED' | 'WAITING';

const _getModalText = (status: addOrderTemplateToCartStatus, props: IAddOrderTemplateToCartComponentProps) => {
    const { dialogStrings } = props;
    const { addToCartFailureMessage, addToCartSuccessMessage, addToCartProcessMessage } = dialogStrings;

    if (status === 'LOADING') {
        return `${addToCartProcessMessage}...`;
    }

    if (status === 'SUCCESS') {
        return addToCartSuccessMessage;
    }

    if (status === 'FAILED') {
        return addToCartFailureMessage;
    }

    return '';
};

const AddOrderTemplate: React.FC<IAddOrderTemplateToCartComponentProps> = (props: IAddOrderTemplateToCartComponentProps) => {
    const [uploadStatus, setUploadStatus] = useState<addOrderTemplateToCartStatus>('WAITING');
    const [modalOpen, setModalOpen] = useState(false);
    const [addedLinesCount, setAddedLinesCount] = useState(0);
    const [linesAddedToCartDialogOpen, setLinesAddedToCartDialogOpen] = useState(false);

    const onClickHandler = async (event: React.MouseEvent<HTMLElement>) => {
        await AddToCartComponentActions.onClick(event, props, setUploadStatus, setModalOpen, setLinesAddedToCartDialogOpen, setAddedLinesCount);
    };
    const closeLinesAddedToCartDialog = () => {
        setLinesAddedToCartDialogOpen(false);
    };

    const modalText = _getModalText(uploadStatus, props);
    const processStatusClass = uploadStatus.toLowerCase();
    const dialogStrings = { closeWindowButtonText: props.dialogStrings.closeWindowButtonText };
    const payLoad = getPayloadObject('click', props.telemetryContent!, TelemetryConstant.AddOrderTemplateToCart);
    const addOrderTemplateAttribute = getTelemetryAttributes(props.telemetryContent!, payLoad);

    const popupProps: IPopupProductsProps = {
        dialogStrings,
        className: 'msc-add-order-template-to-cart',
        processStatusClass,
        text: modalText,
        modalOpen,
        setModalOpen
    };

    const renderModalPopup = <PopupProducts {...popupProps} />;

    const products = props.data.orderTemplate.orderTemplateLines.map(line => line.fullProduct).filter(fullProduct => fullProduct) as FullProduct[];

    return (
        <>
            {renderModalPopup}
            <Button
                className={classnames('msc-add-order-template-to-cart ', props.className)}
                aria-label={props.addToCartText}
                disabled={props.data.orderTemplate.totalLines === 0}
                onClick={onClickHandler}
                {...addOrderTemplateAttribute}
            >
                {props.addToCartText}
            </Button>
            <LinesAddedToCartDialog
                className='msc-lines-added-to-cart-dialog'
                id={props.id}
                typeName={props.typeName}
                context={props.context}
                dialogStrings={props.dialogStrings.linesAddedToCartDialogResources}
                data={{
                    linesCount: addedLinesCount,
                    orderTemplate: props.data.orderTemplate.productList,
                    products
                }}
                modalOpen={linesAddedToCartDialogOpen}
                onClose={closeLinesAddedToCartDialog}
            />
        </>
    );
};

// @ts-expect-error
export const AddOrderTemplateToCartComponent: React.FunctionComponent<IAddOrderTemplateToCartComponentProps> = msdyn365Commerce.createComponent<IAddOrderTemplateToCartComponent>(
    'AddOrderTemplate',
    { component: AddOrderTemplate, ...AddToCartComponentActions }
);
