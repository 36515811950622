import { ArrayExtensions } from '@msdyn365-commerce-modules/retail-actions';
import { Alert, Button, Modal, ModalBody, ModalFooter, ModalHeader } from '@msdyn365-commerce-modules/utilities';
import { FullProduct } from '@msdyn365-commerce/commerce-entities';
import { getUrlSync, IComponentProps, IImageSettings } from '@msdyn365-commerce/core';
import { ProductList } from '@msdyn365-commerce/retail-proxy';
import { ProductDimensionType } from '@msdyn365-commerce/retail-proxy/dist/Entities/CommerceTypes.g';
import React from 'react';
import { OrderTemplateProductsPreviewComponent } from '../order-templates-list/order-template-products-preview';

export interface ILinesAddedToOrderTemplateDialogResources {
    viewOrderTemplateButtonText: string;
    continueShoppingButtonText: string;
    linesAddedToOrderTemplateHeaderItemsOneText: string;
    linesAddedToOrderTemplateHeaderItemsFormatText: string;
    linesAddedToOrderTemplateHeaderLinesOneText: string;
    linesAddedToOrderTemplateHeaderLinesFormatText: string;
    linesAddedToOrderTemplateHeaderMessageText: string;
    linesAddedToOrderTemplateCustomPriceAlertMessageText?: string;
}

export interface ILinesAddedToOrderTemplateDialogData {
    itemsCount: number;
    linesCount: number;
    orderTemplate: ProductList;
    products: FullProduct[];
}

export interface ILinesAddedToOrderTemplateDialogProps extends IComponentProps<ILinesAddedToOrderTemplateDialogData> {
    className: string;
    imageSettings?: IImageSettings;

    dialogStrings: ILinesAddedToOrderTemplateDialogResources;

    modalOpen: boolean | undefined;
}

export const LinesAddedToOrderTemplateDialog = (dialogProps: ILinesAddedToOrderTemplateDialogProps) => {
    const {
        dialogStrings,
        className,
        context,
        modalOpen,
        imageSettings,
        data: {
            orderTemplate,
            products,
            itemsCount,
            linesCount
        }
    } = dialogProps;

    const getOrderTemplatePageUrl = (): string => {
        const url = getUrlSync('ordertemplate', context.actionContext) || '';
        const separator = url.includes('?') ? '&' : '?';
        return `${url}${separator}id=${orderTemplate.Id}`;
    };

    const close: () => void = () => {
        window.location.reload(true);
    };

    const itemsString = itemsCount === 1 ? dialogStrings.linesAddedToOrderTemplateHeaderItemsOneText : dialogStrings.linesAddedToOrderTemplateHeaderItemsFormatText.replace('{0}', itemsCount.toString());
    const lineItemsString = linesCount === 1 ? dialogStrings.linesAddedToOrderTemplateHeaderLinesOneText : dialogStrings.linesAddedToOrderTemplateHeaderLinesFormatText.replace('{0}', linesCount.toString());

    const customPriceDimensionType = 4 as ProductDimensionType.Style;
    const addedProducts = products.filter(product => !(product.ProductDetails.Dimensions?.find(dimension => dimension.DimensionTypeValue === customPriceDimensionType && dimension.DimensionValue?.Value === 'Custom')));
    const filteredProducts = products.filter(product => product.ProductDetails.Dimensions?.find(dimension => dimension.DimensionTypeValue === customPriceDimensionType && dimension.DimensionValue?.Value === 'Custom'));

    return (
        <Modal isOpen={modalOpen} className={`${className}`} toggle={close}>
            <ModalHeader className={`${className}__dialog__header`} toggle={close}>
                <b>
                    {lineItemsString}
                </b>
                <span>
                    {dialogStrings.linesAddedToOrderTemplateHeaderMessageText}
                </span>
            </ModalHeader>
            <ModalBody className={`${className}__dialog__body`}>
                <div className={`${className}__dialog__content`}>
                    <OrderTemplateProductsPreviewComponent
                        context={context}
                        className={`${className}__dialog`}
                        products={addedProducts}
                        imageSettings={imageSettings}
                    />
                    <div className={`${className}__dialog__description`}>
                        <div className={`${className}__dialog__order-template-name`}>
                            {orderTemplate.Name}
                        </div>
                        <div className={`${className}__dialog__order-template-items`}>
                            {itemsString}
                        </div>
                    </div>
                    {ArrayExtensions.hasElements(filteredProducts) ? <>
                        <Alert color='danger' assertive aria-label={dialogStrings.linesAddedToOrderTemplateCustomPriceAlertMessageText} >
                            <div className='msc-alert__header'>
                                <span className='msi-exclamation-triangle' />
                                <span>
                                    {dialogStrings.linesAddedToOrderTemplateCustomPriceAlertMessageText}
                                </span>
                            </div>
                        </Alert>
                        <OrderTemplateProductsPreviewComponent
                            context={context}
                            className={`${className}__dialog`}
                            products={filteredProducts}
                            imageSettings={imageSettings}
                        />
                    </> : null}
                </div>
            </ModalBody>
            <ModalFooter
                className={`${className}__dialog__footer`}
            >
                <Button
                    className={`${className}__dialog__view-order-template-button`}
                    title={dialogStrings.viewOrderTemplateButtonText}
                    href={getOrderTemplatePageUrl()}
                >
                    {dialogStrings.viewOrderTemplateButtonText}
                </Button>
                <Button
                    className={`${className}__dialog__continue-shopping`}
                    title={dialogStrings.continueShoppingButtonText}
                    onClick={close}
                >
                    {dialogStrings.continueShoppingButtonText}
                </Button>
            </ModalFooter>
        </Modal>
    );
};
