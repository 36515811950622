// eslint-disable-next-line unicorn/filename-case
import { format, getPayloadObject, getTelemetryAttributes, IPayLoad, mapToCssModules, UncontrolledTooltip } from '@msdyn365-commerce-modules/utilities';
import classNames from 'classnames';
import debounce from 'lodash/debounce';
import * as React from 'react';
import { ICarouselProps } from './Carousel.props';
import CarouselContext from './CarouselContext';
import CarouselItem from './CarouselItem';

export interface ICarouselState {
    direction: string;
    indicatorClicked: boolean;
}

export type carouselTransition = 'slide' | 'fade';

// tslint:disable:no-any
/**
 * Carousel component.
 */
export default class Carousel extends React.Component<ICarouselProps, ICarouselState> {

    public static defaultProps: Partial<ICarouselProps> = {
        interval: 5000,
        pause: 'hover',
        keyboard: true,
        slide: true,
        hideIndicator: false
    };

    public props: ICarouselProps;

    public state: ICarouselState;

    public cycleInterval?: any;

    private scrollStart: number | undefined;

    private readonly payLoad: IPayLoad;

    private readonly scrollThreshold: number;

    private readonly linkRefPrev: React.RefObject<HTMLAnchorElement>;

    private readonly linkRefNext: React.RefObject<HTMLAnchorElement>;

    private readonly directionTextPrev: string = 'prev';

    private readonly directionTextNext: string = 'next';

    private readonly nextHandleInputThrottledEvent: (event: React.MouseEvent) => void;

    private readonly previousHandleInputThrottledEvent: (event: React.MouseEvent) => void;

    private readonly defaultThrottleThreshold: number = 200;

    private readonly throttleThreshold: number;

    constructor(props: ICarouselProps) {
        super(props);
        this.props = props;
        this.throttleThreshold = this.props.threshold || this.defaultThrottleThreshold;
        this.handleKeyPress = this.handleKeyPress.bind(this);
        this._handleTouchStart = this._handleTouchStart.bind(this);
        this._handleTouchEnd = this._handleTouchEnd.bind(this);
        this.renderItems = this.renderItems.bind(this);
        this.hoverStart = this.hoverStart.bind(this);
        this.scrollThreshold = this.props.touchScrollThreshold !== undefined ? this.props.touchScrollThreshold : 100;
        this.state = {
            direction: 'right',
            indicatorClicked: false
        };
        this._onClickPrev = this._onClickPrev.bind(this);
        this._onClickNext = this._onClickNext.bind(this);
        this._handleKeyPressPrev = this._handleKeyPressPrev.bind(this);
        this._handleKeyPressNext = this._handleKeyPressNext.bind(this);
        this.linkRefPrev = React.createRef();
        this.linkRefNext = React.createRef();
        this.payLoad = getPayloadObject('click', this.props.telemetryContent!, '');
        this.nextHandleInputThrottledEvent = debounce(this._onClickNext, this.throttleThreshold);
        this.previousHandleInputThrottledEvent = debounce(this._onClickPrev, this.throttleThreshold);
    }

    public componentDidMount(): void {
        // Set up the cycle
        if (this.props.ride === 'carousel') {
            this.setInterval();
        }

        // TODO: move this to the specific carousel like bootstrap. Currently it will trigger ALL carousels on the page.
        document.addEventListener('keyup', this.handleKeyPress);
    }

    public componentWillReceiveProps(nextProps: ICarouselProps): void {
        this.setInterval(nextProps);

        const { activeIndex } = this.props;

        if (activeIndex !== null) {
            // Calculate the direction to turn
            if (activeIndex < nextProps.activeIndex) {
                this.setState({ direction: 'right' });
            } else if (activeIndex > nextProps.activeIndex) {
                this.setState({ direction: 'left' });
            }
            this.setState({ indicatorClicked: false });
        }
    }

    public componentWillUnmount(): void {
        this.clearInterval();
        document.removeEventListener('keyup', this.handleKeyPress);
    }

    public setInterval = (props: ICarouselProps = this.props) => {
        // Make sure not to have multiple intervals going...
        this.clearInterval();

        const { interval, autoplay } = props;
        if (interval && !(autoplay === false)) {
            this.cycleInterval = setInterval(() => {
                props.next();
            },
            // tslint:disable-next-line
            interval
            );
        }
    };

    public clearInterval = () => {
        clearInterval(this.cycleInterval);
    };

    public hoverStart = (e: React.MouseEvent) => {
        const { pause, mouseEnter } = this.props;

        if (pause === 'hover') {
            this.clearInterval();
        }

        if (mouseEnter) {
            mouseEnter(e);
        }
    };

    public hoverEnd = (e: React.MouseEvent) => {
        const { pause, mouseLeave } = this.props;

        if (pause === 'hover') {
            this.setInterval();
        }

        if (mouseLeave) {
            mouseLeave(e);
        }
    };

    public handleKeyPress = (e: any) => {
        const { keyboard, previous, next } = this.props;

        if (keyboard) {
            if (e.keyCode === 37) {
                previous();
            } else if (e.keyCode === 39) {
                next();
            }
        }
    };

    public renderItems = (carouselItems: React.ReactNode[], className: string) => {
        const { slide, handleOnExiting, handleOnExited, activeIndex } = this.props;

        const slides = carouselItems && carouselItems.length ? (
            carouselItems.map((slotContent: React.ReactNode, index: number) => {
                return (
                    <CarouselItem key={index} onExiting={handleOnExiting} onExited={handleOnExited}>
                        {slotContent}
                    </CarouselItem>
                );
            })
        ) : (
            []
        );

        return (
            <div className={className}>
                {slides.map((item: any, index: any) => {
                    const isIn = (index === activeIndex);

                    return React.cloneElement(item, {
                        in: isIn,
                        slide
                    });
                })}
            </div>
        );
    };

    public render(): JSX.Element | null {
        const { cssModule, className, transitionType, dismissEnabled, hideFlipper, showPaginationTooltip, items, hideIndicator } = this.props;

        function carouselClass(transitionTypeValue: any): any {
            switch (transitionTypeValue) {
                case 'slide':
                    return 'slide';
                case 'fade':
                    return 'carousel-fade';
                default:
                    return null;
            }
        }

        const showTooltip = showPaginationTooltip !== undefined ? showPaginationTooltip : true;

        const outerClasses = mapToCssModules(classNames(className, 'msc-carousel', carouselClass(transitionType)), cssModule);
        const innerClasses = mapToCssModules(classNames('msc-carousel__inner'), cssModule);
        const dismiss = dismissEnabled && this._renderDismissCarousel();
        const indicators = !hideIndicator && this._renderIndicators(showTooltip);
        const carouselControls = !hideFlipper && this._renderCarouselControls(showTooltip);

        if (items && items.length) {
            // Rendering indicators, slides and controls
            return (
                <CarouselContext.Provider
                    value={{ direction: this.state.direction }}
                >
                    <div
                        className={outerClasses}
                        onMouseEnter={this.hoverStart}
                        onMouseLeave={this.hoverEnd}
                        onTouchStart={this._handleTouchStart}
                        onTouchEnd={this._handleTouchEnd}
                    >
                        {items && items.length && this.renderItems(items, innerClasses)}
                        {items && items.length > 1 && carouselControls}
                        {items && items.length > 1 && indicators}
                        {dismiss}
                    </div>
                </CarouselContext.Provider>
            );
        }
        return (null);

    }

    private _handleTouchStart(evt: React.TouchEvent<HTMLDivElement>): void {
        if (evt.touches.length === 0) {
            this.scrollStart = undefined;
        } else {
            this.scrollStart = evt.touches[0].screenX;
        }
    }

    private _handleTouchEnd(evt: React.TouchEvent<HTMLDivElement>): void {
        if (evt.changedTouches.length > 0 && this.scrollStart !== undefined) {
            const { previous, next } = this.props;
            const newTarget: number = evt.changedTouches[0].screenX;

            const delta = newTarget - this.scrollStart;

            if (delta > this.scrollThreshold) {
                previous();
            }

            if (delta < -this.scrollThreshold) {
                next();
            }
        }

        this.scrollStart = undefined;

    }

    private _renderIndicators(showTooltip: boolean): JSX.Element {
        const { items, activeIndex, cssModule, onIndicatorsClickHandler, indicatorAriaText } = this.props;

        const listClasses = mapToCssModules('msc-carousel__indicators', cssModule);
        const indicators = items && items.length && items.map((item: any, idx: number) => {
            const indicatorClasses = mapToCssModules(classNames(
                { active: activeIndex === idx }
            ), cssModule);

            const label = indicatorAriaText ? format(indicatorAriaText, idx + 1) : '';
            const indicatorId = 'indicatorSlide' + `${idx + 1}`;
            this.payLoad.contentAction.etext = (idx + 1).toString();
            const attribute = getTelemetryAttributes(this.props.telemetryContent!, this.payLoad);

            return (
                <>
                    <li
                        id={indicatorId}
                        aria-label={label}
                        aria-setsize={items.length}
                        aria-posinset={idx + 1}
                        aria-selected={activeIndex === idx}
                        key={`${item.key || item.src}${item.caption}${item.altText}`}
                        role='tab'
                        tabIndex={0}
                        className={indicatorClasses}
                        // tslint:disable-next-line
                        onClick={(e) => {
                            e.preventDefault();
                            onIndicatorsClickHandler(idx);
                            this.setState({ indicatorClicked: true });
                        }}
                        {...attribute}
                    />
                    { showTooltip && <UncontrolledTooltip trigger='hover focus' target={`${indicatorId}`}>
                        {label}
                    </UncontrolledTooltip>}
                </>
            );
        });

        return (
            <ol className={listClasses} role='tablist'>
                {indicators}
            </ol>
        );
    }

    private _renderCarouselControls(showTooltip: boolean): JSX.Element {
        const { cssModule, directionTextPrev, directionTextNext } = this.props;

        const anchorClassesPrev = mapToCssModules('msc-carousel__control__prev', cssModule);
        const anchorClassesNext = mapToCssModules('msc-carousel__control__next', cssModule);
        const iconClassesPrev = mapToCssModules('msc-carousel__control__prev__icon', cssModule);
        const iconClassesNext = mapToCssModules('msc-carousel__control__next__icon', cssModule);
        const screenReaderClasses = mapToCssModules('screen-reader-only', cssModule);
        const indexNumber: number = 0;
        this.payLoad.contentAction.etext = this.directionTextPrev;
        const prevAttribute = getTelemetryAttributes(this.props.telemetryContent!, this.payLoad);
        this.payLoad.contentAction.etext = this.directionTextNext;
        const nextAttribute = getTelemetryAttributes(this.props.telemetryContent!, this.payLoad);

        return (
            <>
                <a
                    className={anchorClassesPrev}
                    role='button'
                    tabIndex={indexNumber}
                    onClick={this.previousHandleInputThrottledEvent}
                    onKeyUp={this._handleKeyPressPrev}
                    ref={this.linkRefPrev}
                    {...prevAttribute}
                >
                    <span className={iconClassesPrev} aria-hidden='true' />
                    <span className={screenReaderClasses}>
                        {directionTextPrev || this.directionTextPrev}
                    </span>
                </a>
                {showTooltip && <UncontrolledTooltip trigger='hover focus' target={this.linkRefPrev}>
                    {directionTextPrev}
                </UncontrolledTooltip>}
                <a
                    className={anchorClassesNext}
                    role='button'
                    tabIndex={indexNumber}
                    onClick={this.nextHandleInputThrottledEvent}
                    onKeyUp={this._handleKeyPressNext}
                    ref={this.linkRefNext}
                    {...nextAttribute}
                >
                    <span className={iconClassesNext} aria-hidden='true' />
                    <span className={screenReaderClasses}>
                        {directionTextNext || this.directionTextNext}
                    </span>
                </a>
                {showTooltip && <UncontrolledTooltip trigger='hover focus' target={this.linkRefNext}>
                    {directionTextNext}
                </UncontrolledTooltip>}
            </>
        );
    }

    private _onClickPrev(e: React.MouseEvent): void {
        e.preventDefault();
        this.props.previous();
    }

    private _onClickNext(e: React.MouseEvent): void {
        e.preventDefault();
        this.props.next();
    }

    private readonly _handleKeyPressPrev = (e: React.KeyboardEvent) => {
        if (e.keyCode === 13) {
            e.preventDefault();
            this.props.previous();
        }
    };

    private readonly _handleKeyPressNext = (e: React.KeyboardEvent) => {
        // Handle Enter key
        if (e.keyCode === 13) {
            e.preventDefault();
            this.props.next();
        }
    };

    private _renderDismissCarousel(): JSX.Element {
        const { handleDismissCarousel, dismissCarouselAriaText } = this.props;
        return (
            <button
                className='msc-carousel__dismiss msi msi-times'
                aria-label={dismissCarouselAriaText}
                onClick={handleDismissCarousel}
            />
        );
    }
}
