// eslint-disable-next-line unicorn/filename-case
import * as React from 'react';
import { PlayerConstants, VideoPlayerNames } from '../data/player-constants';
import { IAgeGateProps } from './AgeGate.props';

/**
 *
 * AgeGate React component for video player.
 *
 */
export interface IDropdownState {
    monthValue: string;
    dayValue: string;
    yearValue: string;
}
// tslint:disable-next-line:completed-docs
export default class AgeGate extends React.PureComponent<IAgeGateProps, IDropdownState> {
    public static defaultProps: Partial<IAgeGateProps> = {
        minmumAge: 18
    };

    private readonly ageGateDiv: React.RefObject<HTMLDivElement> = React.createRef<HTMLDivElement>();

    constructor(props: IAgeGateProps) {
        super(props);
        this._filterDateMonthYear = this._filterDateMonthYear.bind(this);
        this.state = {
            monthValue: 'Month',
            dayValue: 'Day',
            yearValue: 'Year'
        };
    }

    public render(): JSX.Element {
        const enabled = PlayerConstants.regxForAgeGateInputValidation.test(this.state.dayValue) &&
        PlayerConstants.regxForAgeGateInputValidation.test(this.state.monthValue) &&
        PlayerConstants.regxForAgeGateInputValidation.test(this.state.yearValue);
        return (
            <div className={`${VideoPlayerNames.videoplayerContainer}__agegate__container`} ref={this.ageGateDiv} aria-hidden={this.props['aria-hidden']}>
                <div>
                    <div>
                        <h3>
                            Enter your date of birth
                        </h3>
                        <fieldset>
                            <div>
                                {this.renderSelectMonth()}
                            </div>
                            <div>
                                {this.renderSelectDay()}
                            </div>
                            <div>
                                {this.renderSelectYear()}
                            </div>
                            <button disabled={!enabled} onClick={this.props.onSubmitClick}>
                                Submit
                            </button>
                        </fieldset>
                    </div>
                </div>
            </div>
        );
    }

    public getCalculatedAge = () => {
        const day: number = Number(this.state.dayValue);
        const month: number = Number(this.state.monthValue);
        const year: number = Number(this.state.yearValue);
        const today: Date = new Date();
        let age = today.getFullYear() - year;
        const birthdayMonthHasNotHappenedYet: boolean = today.getMonth() + 1 < month;
        const birthdayDayHasNotHappenedYet: boolean = (today.getMonth() + 1 === month && today.getDate() < day);
        if (birthdayMonthHasNotHappenedYet || birthdayDayHasNotHappenedYet) {
            // User has not reached their birth month and/or birth day-of-month in the current year
            age--;
        }
        const minimmumage = Number(this.props.minmumAge);
        if (age >= minimmumage) {
            return true;
        }
        return false;

    };

    // tslint:disable-next-line:no-any
    private _filterDateMonthYear(e: any): void {
        if (e.target.id === 'selectDay') {
            this.setState({ dayValue: e.target.value });
        } else if (e.target.id === 'selectMonth') {
            this.setState({ monthValue: e.target.value });
        } else if (e.target.id === 'selectYear') {
            this.setState({ yearValue: e.target.value });
        }
    }

    private readonly renderSelectMonth = (): JSX.Element => {
        const maxOffset = 12;
        const allMonth = [];
        for (let month = 1; month <= maxOffset; month++) {
            allMonth.push(month);
        }
        const monthList = allMonth.map((month) => {
            return (<option role='menuitem' key={month} value={month}>
                {month.toFixed()}
            </option>);
        });
        return (
            <select
                aria-expanded='false' className={`${VideoPlayerNames.videoplayerContainer}__agegate__select`} role='menu'
                onChange={this._filterDateMonthYear} value={this.state.monthValue} aria-label='Enter your month of birth'
                name='selectMonth' id='selectMonth'>
                <option role='menuitem'>
                    Month
                </option>
                {monthList}
            </select>
        );
    };

    private readonly renderSelectDay = (): JSX.Element => {
        const maxOffset = 31;
        const allDay = [];
        for (let day = 1; day <= maxOffset; day++) {
            allDay.push(day);
        }
        const dayList = allDay.map((day) => {
            return (<option role='menuitem' key={day}>
                {day.toFixed()}
            </option>);
        });
        return (
            <select
                aria-expanded='false'
                className={`${VideoPlayerNames.videoplayerContainer}__agegate__select`}
                role='menu'
                onChange={this._filterDateMonthYear}
                value={this.state.dayValue}
                aria-label='Enter your day of birth'
                name='selectDay'
                id='selectDay'
            >
                <option role='menuitem'>
                    Day
                </option>
                {dayList}
            </select>
        );
    };

    private readonly renderSelectYear = (): JSX.Element => {
        const maxOffset = 100;
        const thisYear = (new Date()).getFullYear();
        const allYear = [];
        for (let year = 1; year <= maxOffset; year++) {
            allYear.push(thisYear - year);
        }
        const yearList = allYear.map((year) => {
            return (<option role='menuitem' key={year}>
                {year.toFixed()}
            </option>);
        });
        return (
            <select
                aria-expanded='false' className={`${VideoPlayerNames.videoplayerContainer}__agegate__select`} role='menu'
                onChange={this._filterDateMonthYear} value={this.state.yearValue} aria-label='Enter your year of birth'
                name='selectYear' id='selectYear'>
                <option role='menuitem'>
                    Year
                </option>
                {yearList}
            </select>
        );
    };
}
