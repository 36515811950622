// eslint-disable-next-line unicorn/filename-case
import { IPlayerProps } from '../../players/player/Player.props';
import { IPlayerMenu, IPlayerMenuItem, MenuCategories, PlayerConstants } from '../data/player-constants';
import { IAudioTrack } from '../data/player-interface';
import { ClosedCaptionTypes, IBinaryReference, ICCFile, IVideoFile, IVideoMetadata, MediaQuality, MediaTypes } from '../data/player-metadata';
import { startsWith } from '../utilities/stringExtensions';
import LocalizationHelper, { PlayerLocKeys } from './localization-helper';

/**
 *
 * Player helper class.
 *
 */
export default class PlayerHelper {

    private playerProps: IPlayerProps;

    private readonly localizationHelper: LocalizationHelper;

    constructor(props: IPlayerProps, localizationHelper: LocalizationHelper) {
        this.playerProps = props;
        this.localizationHelper = localizationHelper;
    }

    public getMetadata(): IVideoMetadata {
        this._mapVideoFiles();
        this._setCCFiles();
        this._setAudioTracks();
        return this.playerProps.playerData.metaData;
    }

    public updatePlayerProps(props: IPlayerProps): void {
        this.playerProps = props;
        this._clearVideoPlayerMetaData();
    }

    public getOptionMenu(): IPlayerMenu | null {

        const audioTracks = this._getAudioTracksMenu();
        if (audioTracks === null) {
            return null;
        }
        const optionMenuItems: IPlayerMenuItem[] = [];

        optionMenuItems.push({
            id: this.addIdPrefix('audioTracks'),
            label: this.localizationHelper.getLocalizedValue(PlayerLocKeys.audio_tracks),
            selected: false,
            selectable: false,
            subMenu: audioTracks
        });

        return {
            category: MenuCategories.Options,
            id: this.addIdPrefix(MenuCategories.Options),
            label: 'Options',
            items: optionMenuItems
        };

    }

    /**
     * @name - getPosterFrameUrl
     * @description - get Poster Frame URL.
     * @private
     * @returns {string}
     */
    public getPosterFrameUrl(): string {
        let posterFrameUrl = '';
        if (!this.playerProps.playerData.options.hidePosterFrame && this.playerProps.playerData.metaData.posterframeUrl) {
            posterFrameUrl = this._removeProtocolFromUrl(this.playerProps.playerData.metaData.posterframeUrl);
            if (this.playerProps.playerData.options.thumbnailFullWidth) {
                if (posterFrameUrl.indexOf('?') > 0) {
                    posterFrameUrl = `${posterFrameUrl}${'&w='}${PlayerConstants.thumbnailFullWidth}${'&h='}${PlayerConstants.thumbnailFullHeight}`;
                } else {
                    posterFrameUrl = `${posterFrameUrl}${'?w='}${PlayerConstants.thumbnailFullWidth}${'&h='}${PlayerConstants.thumbnailFullHeight}`;
                }

            }
        }
        return posterFrameUrl;
    }

    public getVideoFileToPlay(): IVideoFile {
        let videoFile: IVideoFile = {
            url: '',
            mediaType: MediaTypes.MP4,
            quality: MediaQuality.LO
        };
        const videoMetaData: IVideoMetadata = this.playerProps.playerData.metaData;

        const mpVideoFiles = this._getVideoFileByType(MediaTypes.MP4, videoMetaData.videoFiles!);
        const hdVideoFile = this._getVideoFileByQuality(MediaQuality.HDX, mpVideoFiles);
        if (hdVideoFile !== null) {
            videoFile = hdVideoFile;
        } else {
            const hqVideoFile = this._getVideoFileByQuality(MediaQuality.HQ, mpVideoFiles);
            if (hqVideoFile !== null) {
                videoFile = hqVideoFile;
            } else {
                const sdVideoFile = this._getVideoFileByQuality(MediaQuality.SD, mpVideoFiles);
                if (sdVideoFile !== null) {
                    videoFile = sdVideoFile;
                } else {
                    const loVideoFile = this._getVideoFileByQuality(MediaQuality.LO, mpVideoFiles);
                    if (loVideoFile !== null) {
                        videoFile = loVideoFile;
                    }
                }
            }
        }
        return videoFile;
    }

    /**
     * @param errorType
     * @param message
     * @name - onErrorCallback
     * @description - Cal`lback function when error happen.
     * @private
     * @returns {void}
     */
    public onErrorCallback = (errorType: string, message: string): void => {
        console.log(`ErrorType : ${errorType}, Message : ${message}`);
    };

    public isEmptyOrUndefined(value?: string): boolean {
        if (value === undefined || value === '') {
            return true;
        }
        return false;
    }

    /**
     * @name - addIdPrefix
     * @description - Adds the id prefix to form a child id.
     * @private
     * @param {string} childId - The child id to prefix.
     * @returns {string} - The prefixed id or the original id if no prefix is built.
     */
    public addIdPrefix(childId: string): string {
        const prefix = (this.isEmptyOrUndefined(this.playerProps.playerId)) ?
            null :
            `${this.playerProps.playerId}-`;

        return (prefix && !startsWith(childId, prefix, false)) ? (prefix + childId) : childId;
    }

    /**
     * @name - removeIdPrefix
     * @description - Removes the id prefix from a child id.
     * @public
     * @param {string} childId - The child id to prefix.
     * @returns {string} - The un-prefixed id or the original id if no prefix was found.
     */
    public removeIdPrefix(childId: string): string {
        const prefix = (this.isEmptyOrUndefined(this.playerProps.playerId)) ?
            null :
            `${this.playerProps.playerId}-`;

        return (prefix && startsWith(childId, prefix, false)) ? childId.substring(prefix.length) : childId;
    }

    private _getAudioTracksMenu(): IPlayerMenu | null {
        const audioTracksMenuItems: IPlayerMenuItem[] = [];
        const videoMetaData: IVideoMetadata = this.playerProps.playerData.metaData;
        if (videoMetaData.audioTracks !== undefined && videoMetaData.audioTracks.length > 0) {

            // Check if there are multiple "Descriptive Audio" tracks, in which case we will want to show language
            let descriptiveAudioTrackCount = 0;
            for (const track of videoMetaData.audioTracks) {
                if (track.isDescriptiveAudio) {
                    descriptiveAudioTrackCount++;
                }
            }

            let trackIndex: number = 0;
            for (const audioTrack of videoMetaData.audioTracks) {

                let label: string;
                let languageCode: string = '';
                if (audioTrack.isDescriptiveAudio) {
                    // Descriptive audio labels look like:
                    //   "Descriptive audio" -- when only one descriptive audio track exists
                    // or
                    //   "Descriptive audio - English" -- if there is more than one descriptive audio track
                    const descriptiveAudioLabel = this.localizationHelper.getLocalizedValue(PlayerLocKeys.descriptive_audio);
                    if (descriptiveAudioTrackCount > 1) {
                        const language = this.localizationHelper.getLanguageNameFromLocale(audioTrack.languageCode);
                        label = `${descriptiveAudioLabel} - ${language}`;
                    } else {
                        label = descriptiveAudioLabel;
                    }
                    languageCode = `daudio-${audioTrack.languageCode.toLowerCase()}`;
                } else {
                    // Regular tracks display language of the stream only.
                    label = this.localizationHelper.getLanguageNameFromLocale(audioTrack.languageCode);
                    languageCode = audioTrack.languageCode.toLowerCase();
                }

                const audioMenuItem: IPlayerMenuItem = {
                    label,
                    language: languageCode,
                    data: audioTrack.url,
                    id: this.addIdPrefix(`audio-${trackIndex}`),
                    selected: false,
                    selectable: true,
                    persistOnClick: true
                };

                audioTracksMenuItems.push(audioMenuItem);
                trackIndex++;
            }

            audioTracksMenuItems.unshift({
                id: '',
                label: this.localizationHelper.getLocalizedValue(PlayerLocKeys.audio_tracks_default),
                ariaLabel: this.localizationHelper.getLocalizedValue(PlayerLocKeys.audio_tracks_default),
                language: 'default',
                data: 'default',
                selectable: true,
                selected: false,
                persistOnClick: true
            });

            audioTracksMenuItems.unshift({
                id: '',
                label: this.localizationHelper.getLocalizedValue(PlayerLocKeys.audio_tracks),
                ariaLabel: `${this.localizationHelper.getLocalizedValue(PlayerLocKeys.audio_tracks)} - go back to previous menu`,
                selectable: false,
                selected: false,
                persistOnClick: false,
                isBackButton: true
            });
            return {
                category: MenuCategories.AudioTracks,
                id: this.addIdPrefix(MenuCategories.AudioTracks),
                label: this.localizationHelper.getLocalizedValue(PlayerLocKeys.audio_tracks),
                items: audioTracksMenuItems
            };
        }
        return null;

    }

    private _clearVideoPlayerMetaData(): void {
        this.playerProps.playerData.metaData.videoFiles = [];
        this.playerProps.playerData.metaData.audioTracks = [];
        this.playerProps.playerData.metaData.ccFiles = [];
    }

    private _setCCFiles(): void {
        const videoMetaData: IVideoMetadata = this.playerProps.playerData.metaData;
        const ccFiles: ICCFile[] = [];
        if (!(videoMetaData.ccFiles && videoMetaData.ccFiles.length > 0)) {
            if (videoMetaData.videoBinaryReferences && videoMetaData.videoBinaryReferences.length > 0) {
                for (const binaryRef of videoMetaData.videoBinaryReferences) {
                    // Skip all other format except ttml/closedcaption
                    if (((binaryRef.extension && binaryRef.extension === 'ttml') || binaryRef.$type === 'videoClosedCaptionBinaryReference')) {
                        const ccFile: ICCFile = {
                            ccType: ClosedCaptionTypes.TTML,
                            locale: binaryRef.locale!,
                            url: binaryRef.sourceHref!
                        };
                        ccFiles.push(ccFile);
                    }
                }
                this.playerProps.playerData.metaData.ccFiles = ccFiles;
            }
        }
    }

    private _setAudioTracks(): void {
        const videoMetaData: IVideoMetadata = this.playerProps.playerData.metaData;
        const audioTracks: IAudioTrack[] = [];
        if (videoMetaData.videoBinaryReferences && videoMetaData.videoBinaryReferences.length > 0) {
            for (const binaryRef of videoMetaData.videoBinaryReferences) {
                if (binaryRef.$type && binaryRef.$type === 'audioBinaryReference') {
                    const audioTrack: IAudioTrack = {
                        isDescriptiveAudio: binaryRef.audioType === 'descriptiveAudio',
                        bitrate: binaryRef.bitrate!,
                        languageCode: binaryRef.locale === undefined ? '' : binaryRef.locale,
                        name: binaryRef.alias!,
                        url: binaryRef.clientHref!
                    };
                    audioTracks.push(audioTrack);
                }
            }
        }
        this.playerProps.playerData.metaData.audioTracks = audioTracks;
    }

    private _mapVideoFiles(): void {
        const videoMetaData: IVideoMetadata = this.playerProps.playerData.metaData;
        if (!videoMetaData.videoFiles || videoMetaData.videoFiles.length === 0) {
            const videoFiles: IVideoFile[] = [];
            const videoBinaryFiles: IBinaryReference[] = videoMetaData.videoBinaryReferences!;
            if (videoBinaryFiles && videoBinaryFiles.length > 0) {
                for (const binaryRef of videoBinaryFiles) {
                    if (binaryRef.format) {
                        // Skip the unsupported 1001 format
                        if ((binaryRef.format === '1001')) {
                            continue;
                        }

                        // Handle all other video/audio format
                        const mediaTypeAndQuality = this._getMediaTypeAndQuality(binaryRef.format);
                        let urlRef;
                        if (mediaTypeAndQuality.mediaType === MediaTypes.MP4) {
                            urlRef = binaryRef.clientHref;
                        } else {
                            urlRef = binaryRef.sourceHref;
                        }

                        if (urlRef) {
                            urlRef = this._removeProtocolFromUrl(urlRef);
                        }

                        if (urlRef) {
                            videoFiles.push({
                                url: urlRef,
                                quality: mediaTypeAndQuality.quality,
                                mediaType: mediaTypeAndQuality.mediaType,
                                formatCode: binaryRef.format
                            });
                        }
                    }
                }
            }
            this.playerProps.playerData.metaData.videoFiles = videoFiles;
        }
    }

    /**
     * @name - getMediaTypeAndQuality
     * @param format
     * @description - Gets video quality and media type from stream name.
     * @private
     * @param {string} streamName - The stream name.
     * @returns {any}
     */
    private _getMediaTypeAndQuality(format: string): { mediaType: MediaTypes; quality: MediaQuality } {
        let mType: MediaTypes = MediaTypes.MP4;
        let mQuality: MediaQuality = MediaQuality.SD;

        switch (format) {
            case '101':
                mType = MediaTypes.MP4;
                mQuality = MediaQuality.LO;
                break;
            case '102':
                mType = MediaTypes.MP4;
                mQuality = MediaQuality.SD;
                break;
            case '103':
                mType = MediaTypes.MP4;
                mQuality = MediaQuality.HQ;
                break;
            case '104':
                mType = MediaTypes.MP4;
                mQuality = MediaQuality.HDX;
                break;
            case '105':
                mType = MediaTypes.MP4;
                mQuality = MediaQuality.HD;
                break;
            case '1004':
                mType = MediaTypes.SMOOTH;
                break;
            case '1006':
                mType = MediaTypes.HLS;
                break;
            case '1007':
                mType = MediaTypes.DASH;
                break;
            default:
                mType = MediaTypes.MP4;
                mQuality = MediaQuality.SD;
        }

        return {
            mediaType: mType,
            quality: mQuality
        };
    }

    /**
     * @name - removeProtocolFromUrl
     * @description - Removes protocol from url.
     * @private
     * @param {string} url - The url.
     * @returns {string}
     */
    private _removeProtocolFromUrl(url: string): string {
        return url.replace(/(^\w+:|^)\/\//, '//');
    }

    /**
     * @name - getVideoFileByQuality
     * @description - Gets the video file of specified quality.
     * @param videoFiles
     * @private
     * @param {MediaQuality} quality - The desired quality to look for.
     * @param
     * @returns {IVideoFile}
     */
    private _getVideoFileByQuality(quality: MediaQuality, videoFiles: IVideoFile[]): IVideoFile | null {
        let videoFile = null;
        for (const file of videoFiles) {
            if (file.quality === quality) {
                videoFile = file;
                break;
            }
        }

        return videoFile;
    }

    /**
     * @name - getVideoFileByType
     * @param videoFiles
     * @description - Gets the video file of specified type.
     * @private
     * @param {MediaTypes} mediaType - The media type to look for.
     * @returns {IVideoFile}
     */
    private _getVideoFileByType(mediaType: MediaTypes, videoFiles: IVideoFile[]): IVideoFile[] {
        const videoFile: IVideoFile[] = [];
        for (const file of videoFiles) {
            if (file.mediaType === mediaType) {
                videoFile.push(file);
            }
        }
        return videoFile;
    }
}
