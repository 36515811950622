// eslint-disable-next-line unicorn/filename-case
import { isNullOrWhiteSpace, trim } from './stringExtensions';

export function getText(element?: HTMLElement | Element): string {
    return element ? element.textContent || '' : '';
}

/** Add a css class to an element.
 * @param  {HTMLElement} element - The element to add the css class to.
 * @param  {string} cssClass - The css class to add.
 */
export function addClass(element: HTMLElement, cssClass: string): void {
    if ((element) && (!isNullOrWhiteSpace(cssClass)) && (!hasClass(element, cssClass))) {
        if (element.classList) {
            element.classList.add(cssClass);
        } else {
            element.className = trim(`${element.className} ${cssClass}`);
        }
    }
}

/** Checks for the presence of a css class on an element.
 * @param  {HTMLElement} element - The element to check the css class of.
 * @param  {string} cssClass - The css class to check for.
 * @returns True if the element's className has the specified class, otherwise false.
 */
// TODO: user classList for supported browsers
export function hasClass(element: HTMLElement, cssClass: string): boolean {
    if ((!element) || isNullOrWhiteSpace(cssClass)) {
        return false;
    } else if (element.classList) {
        return element.classList.contains(cssClass);
    }
    return (`' ' ${element.className} ' '`).includes(`' ' ${trim(cssClass)}  ' '`);

}

/**
 * Convert node list to array.
 * @param  {NodeListOf<T extends Node>} nodeList
 */
export function nodeListToArray<T extends Node>(nodeList: NodeListOf<T> | NodeListOf<Element> |
HTMLCollection | NodeList | NamedNodeMap): T[] {
    if (!nodeList) {
        return [];
    }

    const elements: T[] = [];

    // eslint-disable-next-line unicorn/no-for-loop
    for (let n = 0; n < nodeList.length; n++) {
        elements.push(<T>nodeList[n]);
    }

    return elements;
}

/** Select elements in a context.
 * @param  {string} selector - Can be class, id or tag selector.
 * @param  {HTMLElement} [context] - Any element to scope the selection. It's optional. If not provided document will be assumed.
 * @returns HTMLElement[].
 */
export function selectElements(selector: string, context?: HTMLElement): HTMLElement[] {
    return selectElementsT<HTMLElement>(selector, context);
}

/** Select elements in a context.
 * @param  {string} selector - Can be class, id or tag selector.
 * @param  {HTMLElement} [context] - Any element to scope the selection. It's optional. If not provided document will be assumed.
 * @returns T[].
 */
export function selectElementsT<T extends HTMLElement>(selector: string, context?: HTMLElement): T[] {
    if (isNullOrWhiteSpace(selector) || selector === '#') {
        return <T[]>[];
    }

    const currentContext: HTMLElement | Document = context || document;

    // Check if the first character is class or id.
    if (/^[#.]?[\w-]+$/.test(selector)) {
        // tslint:disable-next-line: switch-default
        switch (selector[0]) {
            case '.':
                if (currentContext.getElementsByClassName) {
                    return nodeListToArray(currentContext.getElementsByClassName!(selector.slice(1)));
                }
                return nodeListToArray(<NodeListOf<T>>currentContext.querySelectorAll(selector));

            case '#':
                const element = currentContext.querySelector(selector);
                return <T[]>(element ? [element] : []);
        }

        return nodeListToArray(currentContext.getElementsByTagName(selector));
    }

    return nodeListToArray(<NodeListOf<T>>currentContext.querySelectorAll(selector));
}

/** Sets or gets CSS properties.
 * @param  {HTMLElement} element
 * @param  {string} property - The CSS property name.
 * @param  {any} [value] - The value to set on the CSS property.
 * @returns {any} - The value of the CSS property.
 */
// tslint:disable-next-line: no-any
export function css(element: HTMLElement | Node, property: string, value?: any): any {
    if (!element) {
        return null;
    }

    if (value || value === '') {
    // tslint:disable-next-line: no-any
        (<any>element).style[property] = value;
    } else {
    // tslint:disable-next-line: no-any
        value = (<any>element).style[property];

        if (isNullOrWhiteSpace(value)) {
        // tslint:disable-next-line: no-any tslint:disable-next-line: prefer-type-cast
            value = getComputedStyle(element as any);
            value = value[property];
        }

        return value;
    }
}

/**
 * Remove all inner HTML from a parent element - used to delete child elements.
 * @param {HTMLElement} parentElement - The parent element from which all children should be removed.
 * @returns Void.
 */
export function removeInnerHtml(parentElement: HTMLElement): void {
    if (parentElement) {
    // tslint:disable-next-line: no-inner-html
        parentElement.innerHTML = '';
    }
}

/** Select first element from the selected elements using provided selector.
 * @param {string} selector - Element selector.
 * @param {HTMLElement} [context] - An optional context to scope the selection.
 * @returns {HTMLElement} - The first element from the match.
 */
export function selectFirstElement(selector: string, context?: HTMLElement): HTMLElement | null {
    const elementsSelected = selectElementsT<HTMLElement>(selector, context);
    return (!elementsSelected || !elementsSelected.length) ? null : elementsSelected[0];
}
