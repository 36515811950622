// eslint-disable-next-line unicorn/filename-case
import { getPayloadObject, getTelemetryAttributes, IPayLoad, UncontrolledTooltip } from '@msdyn365-commerce-modules/utilities';
import * as React from 'react';
import ActionToggle from '../action-toggle/ActionToggle';
import { LocalizationConstants } from '../helper/localization-helper';
import { IPlayPauseTriggerProps } from './PlayPauseTrigger.props';

/**
 *
 * PlayPauseTrigger React component for video player.
 *
 */
export default class PlayPauseTrigger extends React.PureComponent<IPlayPauseTriggerProps> {
    public static defaultProps: Partial<IPlayPauseTriggerProps> = {
        playAriaLabel: 'play',
        pauseAriaLabel: 'pause'
    };

    private readonly playRef: React.RefObject<HTMLButtonElement> = React.createRef<HTMLButtonElement>();

    private readonly pauseRef: React.RefObject<HTMLButtonElement> = React.createRef<HTMLButtonElement>();

    private readonly payLoad: IPayLoad;

    constructor(props: IPlayPauseTriggerProps) {
        super(props);
        this.payLoad = getPayloadObject('click', this.props.telemetryContent!, '');
    }

    public render(): JSX.Element {
        const { className, playAriaLabel, pauseAriaLabel, style, ...props } = this.props;
        this.payLoad.contentAction.etext = this.props.toggled ? LocalizationConstants.pauseAriaLabel : LocalizationConstants.playAriaLabel;
        const attribute = getTelemetryAttributes(this.props.telemetryContent!, this.payLoad);
        return (
            <div
                className={`${this.props.className}__container`} onClick={this.props.onChange} style={this.props.style}
                role='none'>
                <ActionToggle className={this.props.className} {...props}>
                    <button
                        aria-label={this.props.playAriaLabel}
                        className={`${this.props.className}__playbtn`}
                        ref={this.playRef}
                        {... attribute}
                    />
                    <button
                        aria-label={this.props.pauseAriaLabel}
                        className={`${this.props.className}__pausebtn`}
                        ref={this.pauseRef}
                        {... attribute}
                    />
                </ActionToggle>
                <UncontrolledTooltip trigger='hover focus' target={this.playRef}>
                    {' '}
                    {this.props.playAriaLabel}
                </UncontrolledTooltip>
                <UncontrolledTooltip trigger='hover focus' target={this.pauseRef}>
                    {' '}
                    {this.props.pauseAriaLabel}
                </UncontrolledTooltip>
            </div>
        );
    }
}
