import React from 'react';

import { Button } from '@msdyn365-commerce-modules/utilities';
import { IComponent, IComponentProps, msdyn365Commerce } from '@msdyn365-commerce/core';
import { ProductList } from '@msdyn365-commerce/retail-proxy';
import { IOrderTemplateNameDialogResources, OrderTemplateNameDialog } from '../order-template-name-dialog/order-template-name-dialog.component';

export interface IRenameOrderTemplateComponentProps extends IComponentProps<IRenameOrderTemplateData> {
    className?: string;
    renameOrderTemplateDialogStrings: IOrderTemplateNameDialogResources;
    renameOrderTemplateButtonText: string;
    onRenameTemplate(orderTemplate: ProductList): Promise<void>;
}

interface IRenameOrderTemplateData {
    orderTemplate: ProductList;
}

export interface IRenameOrderTemplateComponent extends IComponent<IRenameOrderTemplateComponentProps> {
}

const RenameOrderTemplateComponentActions = {};

const RenameOrderTemplate: React.FC<IRenameOrderTemplateComponentProps> = (props: IRenameOrderTemplateComponentProps) => {
    const { data: { orderTemplate }, renameOrderTemplateButtonText, renameOrderTemplateDialogStrings, context } = props;
    const [renameOrderTemplateDialogOpen, setOrderTemplateNameDialogOpen] = React.useState(false);
    const onOrderTemplateNameCancel = () => {
        context.telemetry.debug('Rename of template was canceled.');
    };

    const onRenameButtonClick = () => {
        setOrderTemplateNameDialogOpen(true);
    };

    return (
        <>
            <Button className='ms-order-template-action-bar__rename-template-button' onClick={onRenameButtonClick}>
                {renameOrderTemplateButtonText}
            </Button>
            <OrderTemplateNameDialog
                existingOrderTemplate={orderTemplate}
                isUpdateDialog
                className='msc-order-template-name-dialog'
                modalOpen={renameOrderTemplateDialogOpen}
                setModalOpen={setOrderTemplateNameDialogOpen}
                context={context}
                id={props.id}
                data={props.data}
                orderTemplateName={props.data.orderTemplate.Name}
                typeName={props.typeName}
                dialogStrings={renameOrderTemplateDialogStrings}
                onCancel={onOrderTemplateNameCancel}
                onSuccess={props.onRenameTemplate}
            />
        </>
    );
};

// @ts-expect-error
export const RenameOrderTemplateComponent: React.FunctionComponent<IRenameOrderTemplateComponentProps> = msdyn365Commerce.createComponent<IRenameOrderTemplateComponent>(
    'RenameOrderTemplate',
    { component: RenameOrderTemplate, ...RenameOrderTemplateComponentActions }
);
