// eslint-disable-next-line unicorn/filename-case
import { getPayloadObject, getTelemetryAttributes, IPayLoad, ISliderProps, Slider, UncontrolledTooltip } from '@msdyn365-commerce-modules/utilities';
import * as React from 'react';
import ActionToggle from '../action-toggle/ActionToggle';
import { IPlayerMenu, MenuCategories, VideoPlayerNames } from '../data/player-constants';
import { LocalizationConstants } from '../helper/localization-helper';
import Menu from '../menu/menu';
import { KeyCodes } from '../utilities/KeyCodes';
import { IVideoControlsProps } from './VideoControls.props';

export interface IVideoControlState {
    showVolumeSlider: boolean;
    volumeSliderLeftPosition: number;
    volumeSliderTopPosition: number;
    showMenu: boolean;
    menuLeftPosition: number;
    currentMenu?: IPlayerMenu;
    toolTipClass: string;
}

export interface IOffsetPosition {
    offsetLeft: number;
    offsetTop: number;
}
export const ButtonMenuTypes = {
    ClosedCaption: 'close-caption',
    Options: 'options'
};

const volumeSliderDivOffset: number = 162;

/**
 *
 * VideoControls React component for video player.
 *
 */
export default class VideoControls extends React.PureComponent<IVideoControlsProps, IVideoControlState> {
    public static defaultProps: Partial<IVideoControlsProps> = {
        resources: {
            playAriaLabel: LocalizationConstants.playAriaLabel,
            pauseAriaLabel: LocalizationConstants.pauseAriaLabel,
            closeAriaLabel: LocalizationConstants.closeAriaLabel,
            muteAriaLabel: LocalizationConstants.muteAriaLabel,
            unMuteAriaLabel: LocalizationConstants.unMuteAriaLabel,
            fullScreenAriaLabel: LocalizationConstants.fullScreenAriaLabel,
            exitFullScreenAriaLabel: LocalizationConstants.exitFullScreenAriaLabel,
            pausedAriaLabel: LocalizationConstants.pausedAriaLabel,
            playingAriaLabel: LocalizationConstants.playingAriaLabel,
            seekBarLabel: LocalizationConstants.seekBarLabel,
            videoTimeDurationLabel: LocalizationConstants.videoTimeDurationLabel,
            closedCaptionLabel: LocalizationConstants.closedCaptionLabel
        },
        viewportMin: 200
    };

    private static readonly videoControlsClassName: string = `${VideoPlayerNames.videoplayerContainer}__controls`;

    private static readonly volumeAutoHideTimeout: number = 2000;

    private selectedMenuItems: number[] = [];

    private volumeAutoHideTimer: number;

    private focusedMenuItemIndex: number;

    private currentMenu: string;

    private readonly volumeButtonDiv: React.RefObject<HTMLDivElement> = React.createRef<HTMLDivElement>();

    private readonly playButtonRef: React.RefObject<HTMLButtonElement> = React.createRef<HTMLButtonElement>();

    private readonly pauseButtonRef: React.RefObject<HTMLButtonElement> = React.createRef<HTMLButtonElement>();

    private readonly closedCaptionRef: React.RefObject<HTMLButtonElement> = React.createRef<HTMLButtonElement>();

    private readonly optionButtonRef: React.RefObject<HTMLButtonElement> = React.createRef<HTMLButtonElement>();

    private readonly fullscreenButtonRef: React.RefObject<HTMLButtonElement> = React.createRef<HTMLButtonElement>();

    private readonly exitFullScreenButtonRef: React.RefObject<HTMLButtonElement> = React.createRef<HTMLButtonElement>();

    private readonly videoControlDiv: React.RefObject<HTMLDivElement> = React.createRef<HTMLDivElement>();

    private readonly closedCaptionButtonDiv: React.RefObject<HTMLDivElement> = React.createRef<HTMLDivElement>();

    private readonly optionButtonDiv: React.RefObject<HTMLDivElement> = React.createRef<HTMLDivElement>();

    private readonly menuRef: React.RefObject<Menu> = React.createRef<Menu>();

    private readonly muteButtonRef: React.RefObject<HTMLButtonElement> = React.createRef<HTMLButtonElement>();

    private readonly unMuteButtonRef: React.RefObject<HTMLButtonElement> = React.createRef<HTMLButtonElement>();

    private readonly payLoad: IPayLoad;

    constructor(props: IVideoControlsProps) {
        super(props);
        this.volumeAutoHideTimer = 0;
        this.focusedMenuItemIndex = 0;
        this.currentMenu = '';
        this._hideVolumeControl = this._hideVolumeControl.bind(this);
        this._onMenuItemClick = this._onMenuItemClick.bind(this);
        this._reevaluateWidth = this._reevaluateWidth.bind(this);
        this._onClosedCaptionClick = this._onClosedCaptionClick.bind(this);
        this._onDocumentClick = this._onDocumentClick.bind(this);
        this._onOptionsButtonClick = this._onOptionsButtonClick.bind(this);
        this._onMenuBlur = this._onMenuBlur.bind(this);
        this._onMenuFocus = this._onMenuFocus.bind(this);
        this._onMouseOver = this._onMouseOver.bind(this);
        this._onMouseOut = this._onMouseOut.bind(this);
        this._onMenuKeyDown = this._onMenuKeyDown.bind(this);
        this._onMenuKeyUp = this._onMenuKeyUp.bind(this);
        this.state = {
            showVolumeSlider: false,
            volumeSliderLeftPosition: 0,
            volumeSliderTopPosition: -160,
            showMenu: false,
            menuLeftPosition: 0,
            currentMenu: this.props.closedCaptionMenu,
            toolTipClass: ''
        };

        // Construct telemetry attribute to render
        this.payLoad = getPayloadObject('click', this.props.telemetryContent!, '');
    }

    public componentDidUpdate(prevProps: IVideoControlsProps): void {
        if (!this.props.isControlPanelVisible) {
            this.setState({
                showMenu: false
            });
        }

        // Updating the selected menu as setstate in player takes time to update
        if (this.props.currentClosedCaptionLanguage !== prevProps.currentClosedCaptionLanguage ||
            this.props.currentClosedCaptionsSetting !== prevProps.currentClosedCaptionsSetting ||
            this.props.currentAudioTrack !== prevProps.currentAudioTrack) {
            this._updateSelectedMenu();
        }
    }

    // tslint:disable-next-line: max-func-body-length
    public render(): JSX.Element {
        const { className, ...props } = this.props;

        const currentTime = this._toElapsedTimeString(props.playPosition.currentTime);
        const endTime = this._toElapsedTimeString(props.playPosition.endTime);

        const volumeDivStyle = {
            top: this.state.volumeSliderTopPosition,
            left: this.state.volumeSliderLeftPosition
        };

        const menuDivStyle = {
            left: this.state.menuLeftPosition
        };

        this.payLoad.contentAction.etext = this.props.playing ? LocalizationConstants.pauseAriaLabel : LocalizationConstants.playAriaLabel;
        const attribute = getTelemetryAttributes(this.props.telemetryContent!, this.payLoad);
        return (
            <div
                className={className}
                ref={this.videoControlDiv}
                role='none'
                onKeyDown={this.props.handleControlsKeyDownHandler}
                onBlur={this.props.onBlur}
                onFocus={this.props.onFocus}
            >
                <div className={`${VideoControls.videoControlsClassName}__wrapper`}>
                    <div className={`${VideoControls.videoControlsClassName}__playpausebtn__container`}>
                        <ActionToggle className={`${VideoControls.videoControlsClassName}__playpausebtn__wrapper`} onChange={this.props.onPlayClick} toggled={this.props.playing}>
                            <button
                                ref={this.playButtonRef}
                                aria-label={this.props.resources.playAriaLabel}
                                className={`${VideoControls.videoControlsClassName}__playbtn`}
                                {...attribute}
                            />
                            <button
                                ref={this.pauseButtonRef}
                                aria-label={this.props.resources.pauseAriaLabel}
                                className={`${VideoControls.videoControlsClassName}__pausebtn`}
                                {...attribute}
                            />
                        </ActionToggle>
                        <UncontrolledTooltip className={this.state.toolTipClass} trigger='hover focus' target={this.playButtonRef}>
                            {' '}
                            {this.props.resources.playAriaLabel}
                        </UncontrolledTooltip>
                        <UncontrolledTooltip trigger='hover focus' target={this.pauseButtonRef}>
                            {' '}
                            {this.props.resources.pauseAriaLabel}
                        </UncontrolledTooltip>
                    </div>
                    <div className={`${VideoControls.videoControlsClassName}__time`}>
                        <div className={`${VideoControls.videoControlsClassName}__time__screenreader`} aria-hidden={this.props.isMobileDevice} aria-live='polite'>
                            {`${this.props.resources.videoTimeDurationLabel} ${currentTime} / ${endTime}`}
                        </div>
                        <div className={`${VideoControls.videoControlsClassName}__time__text`} aria-hidden='true'>
                            {currentTime}
                            {' '}
                            /
                            {' '}
                            {endTime}
                        </div>
                    </div>
                    <div className={`${VideoControls.videoControlsClassName}__slider__container`}>
                        {this._renderVideoSlider()}
                    </div>
                    {(this.props.closedCaptionMenu) &&
                        this._renderClosedCaptionButton()}
                    {(this.props.optionMenu) &&
                        this._renderOptionButton()}
                    {(this.state.showMenu) &&
                        (<Menu
                            ref={this.menuRef}
                            className={`${VideoControls.videoControlsClassName}__menu`}
                            playerMenu={this.state.currentMenu!}
                            style={menuDivStyle}
                            onDocumentClick={this._onDocumentClick}
                            onMenuItemClick={this._onMenuItemClick}
                            onFocus={this._onMenuFocus}
                            onBlur={this._onMenuBlur}
                            onMouseOver={this._onMouseOver}
                            onMouseOut={this._onMouseOut}
                            onKeyDown={this._onMenuKeyDown}
                            onKeyUp={this._onMenuKeyUp}
                        />)}
                    {(!this.props.isMobileDevice) && <div className={`${VideoControls.videoControlsClassName}__muteunmutebtn__container`} ref={this.volumeButtonDiv}>
                        {this._renderVolumeButton()}
                    </div>}
                    {(!this.props.isMobileDevice) && <div
                        className={`${VideoControls.videoControlsClassName}__volumeslider__container ${this.state.showVolumeSlider ? 'show' : 'hide'}`}
                        onFocus={this.showVolumeControl()}
                        onBlur={this._hideVolumeControl}
                        onMouseOver={this.showVolumeControl()}
                        onMouseOut={this._hideVolumeControl}
                        style={volumeDivStyle}
                        role='presentation'
                    >
                        {this._renderVolumeSlider()}
                    </div>}
                    {this._renderFullScreenButton()}
                </div>
            </div>
        );
    }

    public showVolumeControl = (autohide?: boolean) => () => {
        const offsetPosition = this._getOffsetPosition(this.volumeButtonDiv);
        window && window.clearTimeout(this.volumeAutoHideTimer);

        this.setState({
            showVolumeSlider: true,
            volumeSliderLeftPosition: offsetPosition.offsetLeft,
            volumeSliderTopPosition: offsetPosition.offsetTop - volumeSliderDivOffset + 1
        });
        if (autohide && document.activeElement!.parentElement !== this.volumeButtonDiv.current!.firstChild) {
            this.volumeAutoHideTimer = window && window.setTimeout(() => {
                this._hideVolumeControl();
            }, VideoControls.volumeAutoHideTimeout);
        }
    };

    public setFocusOnPlay(): void {
        this.playButtonRef.current!.focus();
    }

    public setFocusOnMenuButton(): void {
        // Don't set focus if control panel is not visible
        if (this.props.isControlPanelVisible) {
            if (this.currentMenu === ButtonMenuTypes.ClosedCaption) {
                this.closedCaptionRef.current!.focus();
            } else {
                this.optionButtonRef.current!.focus();
            }
        }
    }

    public componentDidMount(): void {
        window && window.addEventListener('resize', this._reevaluateWidth);
        this._reevaluateWidth();
        if (this._isIPhone()) {
            const fullscreen = document.getElementsByClassName(`${VideoControls.videoControlsClassName}__fullscreenbtn__container`);
            fullscreen[0].setAttribute('hidden', 'true');
        }
    }

    public componentWillUnmount(): void {
        window && window.removeEventListener('resize', this._reevaluateWidth);
    }

    private _reevaluateWidth(): void {
        const rowDiv = this.videoControlDiv.current!.children[0];
        let controlBarButtonsWidth = 0;

        // Not able to use for-of loop as it causes another ts-lint issue because rowDiv.children is a HTMLCollection object and HTMLCollection
        // is not an array type and string type. So I am surpressing the ts-lint error here rather than other error
        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < rowDiv.children.length; i++) {
            if (rowDiv.children[i].children[0] !== undefined && rowDiv.children[i].children[0].className.includes('action-toggle')) {
                controlBarButtonsWidth += rowDiv.children[i].clientWidth;
            }
        }

        const sliderWidth = controlBarButtonsWidth + 100;
        rowDiv.children[2].setAttribute('style', `${'width: calc(100% - '}${sliderWidth}${'px)'}`);
        if (this.state.showVolumeSlider) {
            this._hideVolumeControl();
        }
        if (!this.props.isMobileDevice) {
            const offsetPosition = this._getOffsetPosition(this.volumeButtonDiv);
            this.setState({
                volumeSliderLeftPosition: offsetPosition.offsetLeft,
                volumeSliderTopPosition: offsetPosition.offsetTop - volumeSliderDivOffset
            });
        }
        if (this.state.showMenu) {
            this.setState({
                showMenu: false
            });
        }

        const videoControlWidth = this.videoControlDiv.current!.clientWidth;
        const viewportMax = window && window.innerWidth;
        this._applyTooltipClassOnWidthChange(videoControlWidth, viewportMax);
    }

    private readonly _onDocumentClick = (e: Event): void => {
        const target = e.target as HTMLElement;
        if (!(((this.currentMenu === ButtonMenuTypes.ClosedCaption) && (target === this.closedCaptionButtonDiv.current!.firstChild || target.parentElement === this.closedCaptionButtonDiv.current!.firstChild)) ||
        ((this.currentMenu === ButtonMenuTypes.Options) && (target === this.optionButtonDiv.current!.firstChild || target.parentElement === this.optionButtonDiv.current!.firstChild)))
        ) {
            this.setState({
                showMenu: !this.state.showMenu
            });
        }
    };

    private _onMenuFocus(e: Event): void {
        const button: HTMLButtonElement = e.target as HTMLButtonElement;
        const hasEvents = button.getAttribute('data-has-events');
        if (hasEvents) {
            this.props.onMenuItemFocus({
                category: button.parentElement!.parentElement!.parentElement!.getAttribute('data-category')!,
                id: button.parentElement!.id,
                data: button.getAttribute('data-info')!
            });
        }
    }

    private _onMenuBlur(e: Event): void {
        const button: HTMLButtonElement = e.target as HTMLButtonElement;
        const hasEvents = button.getAttribute('data-has-events');
        if (hasEvents) {
            this.props.onMenuItemBlur();
        }
    }

    private _onMouseOver(e: Event): void {
        const button: HTMLButtonElement = e.target as HTMLButtonElement;
        const hasEvents = button.getAttribute('data-has-events');
        if (hasEvents) {
            this.props.onMenuItemFocus({
                category: button.parentElement!.parentElement!.parentElement!.getAttribute('data-category')!,
                id: button.parentElement!.id,
                data: button.getAttribute('data-info')!
            });
        }
    }

    private _onMouseOut(e: Event): void {
        const button: HTMLButtonElement = e.target as HTMLButtonElement;
        const hasEvents = button.getAttribute('data-has-events');
        if (hasEvents) {
            this.props.onMenuItemBlur();
        }
    }

    private _onMenuKeyDown(e: Event): void {
        this._onMenuKeyPressed(e as unknown as KeyboardEvent);
    }

    private _onMenuKeyUp(event: Event): void {
        const e = event as unknown as KeyboardEvent;
        const key = e.keyCode;

        if (key === KeyCodes.Space) {
            event.preventDefault();
        }
    }

    /**
     * @name - onMenuKeyPressed
     * @description - Event handler for menu's keyboard events.
     * @private
     * @param {KeyboardEvent} event - The dialog that is allowed to remain open.
     * @returns {void}
     */
    // tslint:disable-next-line: max-func-body-length cyclomatic-complexity
    private _onMenuKeyPressed(event: KeyboardEvent): void {
        const key = event.keyCode;
        const target = event.target as HTMLElement;
        const currentMenuItem = this.state.currentMenu!;
        this._resetFocusForCurrentMenu(currentMenuItem);

        // tslint:disable-next-line: switch-default
        switch (key) {
            case KeyCodes.ArrowLeft:
            case KeyCodes.ArrowRight:
                event.stopPropagation();
                event.preventDefault();
                if (key === KeyCodes.ArrowRight && target.getAttribute('data-has-children') === 'true') {
                    this._onMenuItemClick(event);
                }
                if (key === KeyCodes.ArrowLeft && target.getAttribute('data-isbackbutton') === 'true') {
                    this._onMenuItemClick(event);
                }
                break;

            case KeyCodes.Enter:
            case KeyCodes.Space:
                event.preventDefault();
                this._onMenuItemClick(event);
                break;

            case KeyCodes.ArrowUp:
            case KeyCodes.ArrowDown:
                event.stopPropagation();
                event.preventDefault();

                if (key === KeyCodes.ArrowUp) {
                    this.focusedMenuItemIndex -= 1;
                    if (this.focusedMenuItemIndex < 0) {
                        this.focusedMenuItemIndex = currentMenuItem.items.length - 1;
                    }
                } else {
                    this.focusedMenuItemIndex = ((this.focusedMenuItemIndex + 1) % currentMenuItem.items.length);
                }

                currentMenuItem.items[this.focusedMenuItemIndex].hasFocus = true;
                this.setState({
                    currentMenu: currentMenuItem
                }, () => {
                    if (this.menuRef.current) {
                        this.menuRef.current.forceUpdate();
                    }
                });
                break;

            case KeyCodes.PageUp:
            case KeyCodes.Home:
                event.stopPropagation();
                event.preventDefault();
                this.focusedMenuItemIndex = 0;
                currentMenuItem.items[this.focusedMenuItemIndex].hasFocus = true;
                this.setState({
                    currentMenu: currentMenuItem
                }, () => {
                    if (this.menuRef.current) {
                        this.menuRef.current.forceUpdate();
                    }
                });
                break;

            case KeyCodes.End:
            case KeyCodes.PageDown:
                event.stopPropagation();
                event.preventDefault();
                this.focusedMenuItemIndex = currentMenuItem.items.length - 1;
                currentMenuItem.items[this.focusedMenuItemIndex].hasFocus = true;
                this.setState({
                    currentMenu: currentMenuItem
                }, () => {
                    if (this.menuRef.current) {
                        this.menuRef.current.forceUpdate();
                    }
                });
                break;

            case KeyCodes.Escape:
                this.setFocusOnMenuButton();
                this.setState({
                    showMenu: false
                });
                break;
            case KeyCodes.Tab:
                if (((this.focusedMenuItemIndex < currentMenuItem.items.length - 1 || this.focusedMenuItemIndex === currentMenuItem.items.length - 1 && event.shiftKey) && this.focusedMenuItemIndex >= 0) && !(
                    this.focusedMenuItemIndex === 0 && event.shiftKey)) {
                    this.focusedMenuItemIndex += event.shiftKey ? -1 : 1;

                    currentMenuItem.items[this.focusedMenuItemIndex].hasFocus = true;
                    this.setState({
                        currentMenu: currentMenuItem
                    });
                } else {
                    event.stopPropagation();
                    event.preventDefault();
                    this.setState({
                        showMenu: false
                    });
                    if (event.shiftKey) {
                        this.setFocusOnMenuButton();
                    } else if (this.currentMenu === ButtonMenuTypes.ClosedCaption && this.optionButtonRef.current) {
                        this.optionButtonRef.current.focus();
                    } else {
                        this.props.isMuted ? this.unMuteButtonRef.current!.focus() : this.muteButtonRef.current!.focus();
                    }
                }
        }
    }

    private _onMenuItemClick(e: KeyboardEvent): void {
        const button: HTMLButtonElement = e.currentTarget as HTMLButtonElement;
        if (button.getAttribute('data-has-children')! === 'true') {
            const index: number = Number(button.getAttribute('data-itemindex')!);
            this.selectedMenuItems.push(index);
            const currentMenuItem = this._getSelectedMenuFromList();
            this.focusedMenuItemIndex = 0;
            currentMenuItem.items[this.focusedMenuItemIndex].hasFocus = true;
            this.setState({
                currentMenu: currentMenuItem
            });
        } else if (button.getAttribute('data-isbackbutton')! === 'true') {
            this.focusedMenuItemIndex = this.selectedMenuItems.pop()!;
            const currentMenuItem = this._getSelectedMenuFromList();
            currentMenuItem.items[this.focusedMenuItemIndex].hasFocus = true;
            this.setState({
                currentMenu: currentMenuItem
            });
        } else {
            const currentMenuItem = this._getSelectedMenuFromList();
            this._resetFocusForCurrentMenu(currentMenuItem);
            const index: number = Number(button.getAttribute('data-itemindex')!);
            this.focusedMenuItemIndex = index;
            const data = this.currentMenu === ButtonMenuTypes.ClosedCaption ? button.getAttribute('data-info') :
                JSON.stringify({ url: button.getAttribute('data-info')!, lang: button.getAttribute('lang')! });
            this.props.onMenuItemClick({
                category: button.parentElement!.parentElement!.parentElement!.getAttribute('data-category')!,
                id: button.parentElement!.id,
                data: data!
            });
        }
        this._updateSelectedMenu();
    }

    private _resetFocusForCurrentMenu(currentMenu: IPlayerMenu): void {
        currentMenu.items.forEach(item => item.hasFocus = false);
    }

    private _getSelectedMenuFromList(): IPlayerMenu {
        let menuItem = this.currentMenu === ButtonMenuTypes.ClosedCaption ? this.props.closedCaptionMenu! : this.props.optionMenu!;
        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < this.selectedMenuItems.length; i++) {
            menuItem = menuItem.items[this.selectedMenuItems[i]].subMenu!;
        }
        return menuItem;
    }

    private _updateSelectedMenu(): void {
        const currentMenuItem = this._getSelectedMenuFromList();
        if (currentMenuItem) {

            // tslint:disable-next-line: switch-default
            switch (currentMenuItem.category) {
                case MenuCategories.ClosedCaption:
                    currentMenuItem.items.forEach((item) => {
                        if (item.data && item.data === this.props.currentClosedCaptionLanguage) {
                            item.selected = true;
                        } else {
                            item.selected = false;
                        }
                    });
                    break;
                case MenuCategories.ClosedCaptionSettings:
                    currentMenuItem.items.forEach((item) => {
                    // tslint:disable-next-line: forin no-for-in
                        for (const setting in this.props.currentClosedCaptionsSetting) {
                            const data = `${setting}:${this.props.currentClosedCaptionsSetting[setting]}`;
                            if (item.data && item.data === data) {
                                item.selected = true;
                                break;
                            } else {
                                item.selected = false;
                            }
                        }
                    });
                    break;
                case MenuCategories.AudioTracks:
                    currentMenuItem.items.forEach((item) => {
                        if (item.language && item.language === this.props.currentAudioTrack) {
                            item.selected = true;
                        } else {
                            item.selected = false;
                        }
                    });
            }

            this.setState({
                currentMenu: currentMenuItem
            }, () => {
                if (this.menuRef.current) {
                    this.menuRef.current.forceUpdate();
                }
            });
        }
    }

    private readonly _renderClosedCaptionButton = (): JSX.Element => {
        return (
            <div className={`${VideoControls.videoControlsClassName}__closedcaption__container`} ref={this.closedCaptionButtonDiv}>
                <button
                    ref={this.closedCaptionRef}
                    aria-label={this.props.resources.closedCaptionLabel}
                    className={`${VideoControls.videoControlsClassName}__closedcaptionbtn`}
                    onClick={this._onClosedCaptionClick}
                />
                <UncontrolledTooltip trigger='hover focus' target={this.closedCaptionRef}>
                    {this.props.resources.closedCaptionLabel}
                </UncontrolledTooltip>
            </div>
        );
    };

    private readonly _renderOptionButton = (): JSX.Element => {
        return (
            <div className={`${VideoControls.videoControlsClassName}__moreoptions__container`} ref={this.optionButtonDiv}>
                <button
                    ref={this.optionButtonRef}
                    aria-label={this.props.resources.optionButtonLabel}
                    className={`${VideoControls.videoControlsClassName}__moreoptionsbtn`}
                    onClick={this._onOptionsButtonClick}
                />
                <UncontrolledTooltip trigger='hover focus' target={this.optionButtonRef}>
                    {this.props.resources.optionButtonLabel}
                </UncontrolledTooltip>
            </div>
        );
    };

    private readonly _renderFullScreenButton = (): JSX.Element => {
        this.payLoad.contentAction.etext = this.props.isInFullScreen ? LocalizationConstants.exitFullScreenAriaLabel : LocalizationConstants.fullScreenAriaLabel;
        const attribute = getTelemetryAttributes(this.props.telemetryContent!, this.payLoad);
        return (
            <div className={`${VideoControls.videoControlsClassName}__fullscreenbtn__container`}>
                <ActionToggle
                    onChange={this.props.toggleFullScreen}
                    toggled={this.props.isInFullScreen}
                    className={`${VideoControls.videoControlsClassName}__fullscreenbtn__wrapper`}
                >
                    <button
                        ref={this.fullscreenButtonRef}
                        aria-label={this.props.resources.fullScreenAriaLabel}
                        className={`${VideoControls.videoControlsClassName}__fullscreenbtn`}
                        {...attribute}
                    />
                    <button
                        ref={this.exitFullScreenButtonRef}
                        aria-label={this.props.resources.exitFullScreenAriaLabel}
                        className={`${VideoControls.videoControlsClassName}__exitfullscreenbtn`}
                        {...attribute}
                    />
                </ActionToggle>
                <UncontrolledTooltip className={`${VideoControls.videoControlsClassName}__fullscreenbtn__tooltip`} trigger='hover focus' target={this.fullscreenButtonRef}>
                    {' '}
                    {this.props.resources.fullScreenAriaLabel}
                </UncontrolledTooltip>
                <UncontrolledTooltip trigger='hover focus' target={this.exitFullScreenButtonRef}>
                    {' '}
                    {this.props.resources.exitFullScreenAriaLabel}
                </UncontrolledTooltip>
            </div>
        );
    };

    private readonly _renderVolumeButton = (): JSX.Element => {
        this.payLoad.contentAction.etext = this.props.isMuted ? LocalizationConstants.unMuteAriaLabel : LocalizationConstants.muteAriaLabel;
        const attribute = getTelemetryAttributes(this.props.telemetryContent!, this.payLoad);
        const volumeButtonProps = {
            className: `${VideoControls.videoControlsClassName}__muteunmutebtn__wrapper`,
            onChange: this.props.onMuteClick,
            toggled: this.props.isMuted,
            onFocus: this.showVolumeControl(),
            onBlur: this._hideVolumeControl,
            onMouseOver: this.showVolumeControl(),
            onMouseOut: this._hideVolumeControl
        };

        return (
            <ActionToggle {...volumeButtonProps}>
                <button
                    aria-label={this.props.resources.muteAriaLabel}
                    ref={this.muteButtonRef}
                    className={`${VideoControls.videoControlsClassName}__mutebtn`}
                    {...attribute}
                />
                <button
                    aria-label={this.props.resources.unMuteAriaLabel}
                    ref={this.unMuteButtonRef}
                    className={`${VideoControls.videoControlsClassName}__unmutebtn`}
                    {...attribute}
                />
            </ActionToggle>
        );
    };

    private readonly _renderVideoSlider = (): JSX.Element => {
        const videoProps: ISliderProps = {
            id: 'VideoSlider',
            inForm: false,
            className: `${VideoControls.videoControlsClassName}__slidercontainer`,
            min: this.props.playPosition.startTime,
            max: this.props.playPosition.endTime,
            ariaLabel: this.props.resources.seekBarLabel,
            fillTrackValue: this.props.playPosition.bufferedDuration,
            step: this.props.videoSeekSteps || 1,
            orientation: 'horizontal',
            showProgressBar: true,
            onChange: this.props.onProgressChange,
            showLabels: false,
            showTooltip: true,
            shouldSliderAdjustToRTL: false,
            handleTooltipText: this._handleProgressToolTipText(this.props.playPosition.currentTime),
            sliderThumbs: [
                {
                    id: 'VideoSliderThumb',
                    value: this.props.playPosition.currentTime,
                    ariaValueText: this.props.playPosition.currentTime.toString(),
                    ariaLabel: this.props.resources.sliderThumbLabel
                }
            ]
        };

        return (
            <Slider {...videoProps} />
        );
    };

    private readonly _renderVolumeSlider = (): JSX.Element => {
        const volumeSliderProps: ISliderProps = {
            id: 'VolumeSlider',
            inForm: false,
            className: `${VideoControls.videoControlsClassName}__volumeslider`,
            min: 0,
            max: 100,
            step: this.props.volumeSeekSteps || 5,
            orientation: 'vertical',
            showProgressBar: false,
            onChange: this.props.onVolumeChange,
            onFocus: this.showVolumeControl(),
            onBlur: this._hideVolumeControl,
            showLabels: false,
            showTooltip: true,
            shouldSliderAdjustToRTL: false,
            sliderThumbs: [
                {
                    id: 'VolumeSliderThumb',
                    value: Math.round(this.props.currentVolume),
                    ariaValueText: Math.round(this.props.currentVolume).toString(),
                    ariaLabel: this.props.resources.volumeThumbLabel
                }
            ]
        };
        return (
            <Slider {...volumeSliderProps} />
        );
    };

    private _getOffsetPosition(refDiv: React.RefObject<HTMLDivElement>): IOffsetPosition {
        const buttonWidth = refDiv.current!.children[0].clientWidth;
        const elementwidth = refDiv.current!.offsetWidth;
        const offsetLeft = refDiv.current!.offsetLeft + (elementwidth - buttonWidth) / 2;
        const offsetTop = refDiv.current!.offsetTop;
        return {
            offsetLeft,
            offsetTop
        };
    }

    private _onClosedCaptionClick(): void {
        this.currentMenu = ButtonMenuTypes.ClosedCaption;
        const offsetLeft = this._getOffsetPosition(this.closedCaptionButtonDiv).offsetLeft;
        this.focusedMenuItemIndex = 0;
        this.selectedMenuItems = [];
        const closedCaptionMenu = this.props.closedCaptionMenu!;
        this._resetFocusForCurrentMenu(closedCaptionMenu);
        closedCaptionMenu.items[this.focusedMenuItemIndex].hasFocus = true;
        this.setState({
            showMenu: (this.currentMenu === ButtonMenuTypes.ClosedCaption && !this.state.showMenu),
            menuLeftPosition: offsetLeft - 120,
            currentMenu: closedCaptionMenu
        });
        this._updateSelectedMenu();
    }

    private _onOptionsButtonClick(): void {
        this.currentMenu = ButtonMenuTypes.Options;
        const offsetLeft = this._getOffsetPosition(this.optionButtonDiv).offsetLeft;
        this.focusedMenuItemIndex = 0;
        this.selectedMenuItems = [];
        const optionMenu = this.props.optionMenu!;
        this._resetFocusForCurrentMenu(optionMenu);
        optionMenu.items[this.focusedMenuItemIndex].hasFocus = true;
        this.setState({
            showMenu: (this.currentMenu === ButtonMenuTypes.Options && !this.state.showMenu),
            menuLeftPosition: offsetLeft - 120,
            currentMenu: optionMenu
        });
        this._updateSelectedMenu();
    }

    private _hideVolumeControl(): void {
        window && window.clearTimeout(this.volumeAutoHideTimer);
        this.setState({
            showVolumeSlider: false
        });
    }

    private readonly _handleProgressToolTipText = (tooltip: number) => () => {
        return this._toElapsedTimeString(tooltip);
    };

    private _toElapsedTimeString(seconds: number): string {
        const negative = seconds < 0;
        if (negative) {
            seconds *= -1;
        }

        const hours = Math.floor(seconds / 3600);
        const remainder = seconds % 3600;
        const minutes = Math.floor(remainder / 60);

        let elapsedTime = '';
        elapsedTime = hours > 0 ? `${hours}:` : '';

        seconds = Math.floor(remainder % 60);
        elapsedTime += `${(minutes < 10 ? '0' : '') + minutes.toString()}`;
        elapsedTime += `:${seconds === 0 ? '00' : (seconds < 10 ? '0' : '') + seconds.toString()}`;

        return negative ? `-${elapsedTime}` : elapsedTime;
    }

    private _applyTooltipClassOnWidthChange(videoControlWidth: number, viewportMax: number): void {
        if (videoControlWidth) {
            this.setState({
                toolTipClass: (videoControlWidth > this.props.viewportMin! && videoControlWidth < viewportMax) ? `${VideoControls.videoControlsClassName}__playpausebtn__tooltip` : ''
            });
        }
    }

    private _isIPhone(): boolean {
        const useragent = window && window.navigator.userAgent;
        return !!useragent.match(/iphone/i);
    }
}
