import { IAudioTrack } from './player-interface';

/**
 * @interface ICCFile
 * @description - The data contract interface used for storing references to closed caption files.
 */
export interface ICCFile {
    url: string;
    locale: string;
    ccType?: ClosedCaptionTypes;
}

/**
 * @interface IDownloadableFile
 * @description - The data contract interface used for storing references downloadable media files.
 */
export interface IDownloadableFile {
    mediaType: DownloadableMediaTypes;
    url: string;
    locale: string;
}

/**
 * @enum MediaTypes
 * @description - Enum for supported media types.
 */
export enum MediaTypes {
    MP4 = 'MP4',
    DASH = 'DASH',
    SMOOTH = 'SMOOTH',
    HLS = 'HLS'
}

/**
 * @enum MediaQuality
 * @description - Enum for supported media quality.
 */
export enum MediaQuality {
    HD = 'HD',
    HDX = 'HDX',
    HQ = 'HQ',
    SD = 'SD',
    LO = 'LO'
}

/**
 * @enum ClosedCaptionTypes
 * @description - Enum for supported closed caption types.
 */
export enum ClosedCaptionTypes {
    VTT = 'VTT',
    TTML = 'TTML'
}

/**
 * @enum DownloadableMediaTypes
 * @description - Enum for supported downloadable media types.
 */
export enum DownloadableMediaTypes {
    transcript = 'transcript',
    audio = 'audio',
    video = 'video',
    videoWithCC = 'videoWithCC'
}

/**
 * Cms datatype of video binary reference.
 */
export interface IVideoBinaryReference {

    // / <summary>Gets the video binary file format.</summary>
    format: string;

    // / <summary>Gets the video bit rate.</summary>
    frameRate: number;

    // / <summary>Gets the URL used on the clients to play the video.</summary>
    ClientHref: string;
}

/**
 * Cms datatype of video transcript binary reference.
 */
export interface IVideoTranscriptBinaryReference {

    // / <summary>Gets the locale.</summary>
    locale: string;

    // / <summary>Gets the URL used on the clients to play the video.</summary>
    clientHref: string;
}

/**
 * Cms datatype of video closed captions binary reference.
 */
export interface IVideoClosedCaptionBinaryReference {

    // / <summary>Gets the locale.</summary>
    locale: string;

    // / <summary>Gets the URL used on the clients to play the video.</summary>
    clientHref: string;
}

/**
 * Cms datatype of video closed captions binary reference.
 */
export interface IAudioBinaryReference {

    // / <summary>Gets the locale.</summary>
    locale: string;

    // / <summary>Gets the audio type.</summary>
    audioType: string;

    // / <summary>Gets the URL used on the clients to play the video.</summary>
    clientHref: string;
}

/**
 * IVideoMetadata.
 * @description - The data contract interface used for storing references to video metadata.
 */
export interface IVideoMetadata {
    playerName?: string;
    videoId?: string;
    title?: string;
    description?: string;
    duration?: number;
    posterframeUrl?: string;
    shareUrl?: string;
    videoFiles?: IVideoFile[];
    externalPlayerUrl?: string;
    externalSourceId?: string;
    interactiveTriggersEnabled?: boolean;
    interactiveTriggersUrl?: string;
    minimumAge?: number;
    videoBinaryReferences?: IBinaryReference[];
    ccFiles?: ICCFile[];
    audioTracks?: IAudioTrack[];
}

/**
 * @description - The data contract interface used for storing references to video files.
 */
export interface IVideoFile {
    url: string;
    mediaType: MediaTypes;
    quality: MediaQuality;
    width?: number;
    height?: number;
    formatCode?: string;
}

/**
 * Cms datatype of BinaryReference.
 */
export interface IBinaryReference {
    format?: string;
    bitrate?: number;
    clientHref?: string;
    height?: number;
    width?: number;
    alias?: string;
    contentType?: string;
    description?: string;
    extension?: string;
    sizeInBytes?: number;
    sourceHref?: string;
    href?: string;
    locale?: string;
    $type?: string;
    audioType?: string;
}
