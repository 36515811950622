import { getFallbackImageUrl } from '@msdyn365-commerce-modules/retail-actions';
import { FullProduct } from '@msdyn365-commerce/commerce-entities';
import { ICoreContext, IImageSettings, Image } from '@msdyn365-commerce/core';
import * as React from 'react';

export interface IOrderTemplateProductsPreviewComponentProps {
    className: string;
    products: FullProduct[];
    context: ICoreContext;
    imageSettings?: IImageSettings;
}

export const OrderTemplateProductsPreviewComponent = (props: IOrderTemplateProductsPreviewComponentProps) => {
    const { products, className, imageSettings } = props;

    return (
        <div className={`${className}__products`}>
            {renderOrderTemplateImages(props.context, `${className}__products`, products, imageSettings)}
        </div>
    );
};

const renderOrderTemplateImage = (
    context: ICoreContext, className: string, product: FullProduct, imageSettings?: IImageSettings) => {
    const defaultImageSettings: IImageSettings = {
        viewports: {
            xs: { q: 'w=140&h=140&m=6', w: 0, h: 0 },
            lg: { q: 'w=140&h=140&m=6', w: 0, h: 0 },
            xl: { q: 'w=140&h=140&m=6', w: 0, h: 0 }
        },
        lazyload: true
    };
    return (
        <div className={`${className}__image-props`}>
            <Image
                src={product.ProductDetails.PrimaryImageUrl || ''}
                fallBackSrc={
                    getFallbackImageUrl(product.ProductDetails.ItemId, context.actionContext.requestContext.apiSettings) || ''
                }
                gridSettings={context.request.gridSettings!}
                imageSettings={imageSettings || defaultImageSettings}
                loadFailureBehavior='empty'
            />
        </div>
    );
};

const renderOrderTemplateImages = (
    context: ICoreContext, className: string, products: FullProduct[], imageSettings?: IImageSettings): JSX.Element | null => {
    return (
        <div className={`${className}__image-container`}>
            <div className='row'>
                <div className='column'>
                    {
                        products.length > 0 && renderOrderTemplateImage(context, className, products[0], imageSettings)
                    }
                </div>
                <div className='column'>
                    {
                        products.length > 1 && renderOrderTemplateImage(context, className, products[1], imageSettings)
                    }
                </div>
            </div>
            <div className='row'>
                <div className='column'>
                    {
                        products.length > 2 && renderOrderTemplateImage(context, className, products[2], imageSettings)
                    }
                </div>
                <div className='column'>
                    {
                        products.length > 3 && renderOrderTemplateImage(context, className, products[3], imageSettings)
                    }
                </div>
            </div>
        </div>
    );
};
