import { IActionContext } from '@msdyn365-commerce/core';
import { updateCartLinesAsync } from '@msdyn365-commerce/retail-proxy/dist/DataActions/CartsDataActions.g';
import { Cart, CartLine } from '@msdyn365-commerce/retail-proxy/dist/Entities/CommerceTypes.g';
import { ICartActionResultWithCart } from './cart-action-result';

export default async function updateCartLineQuantityInternal(
    cart: Readonly<Cart | undefined>,
    cartLineId: string,
    newQuantity: number,
    actionContext: IActionContext,
    isUsingDefaultOrderSettingsMax: boolean
): Promise<ICartActionResultWithCart> {
    if (isUsingDefaultOrderSettingsMax) {
        return updateCartLineQuantityValidateMaxAgainstDefaultOrderSettings(cart, cartLineId, newQuantity, actionContext);
    } else {
        return updateCartLineQuantityValidateMaxAgainstSiteSettings(cart, cartLineId, newQuantity, actionContext);
    }
}
async function updateCartLineQuantityValidateMaxAgainstDefaultOrderSettings(cart: Readonly<Cart | undefined>, cartLineId: string, newQuantity: number, actionContext: IActionContext
): Promise<ICartActionResultWithCart> {
    if (!cart || !cart.CartLines) {
        return { cart: undefined, status: 'FAILED'};
    }

    const matchingLines = cart.CartLines.filter(cartLine => cartLine.LineId === cartLineId);

    if (matchingLines.length === 0) {
        return { cart: undefined, status: 'FAILED'};
    }

    const copyCartLine: CartLine = {...matchingLines[0]};

    copyCartLine.Quantity = newQuantity;

    return updateCartLinesAsync({ callerContext: actionContext}, cart.Id, [copyCartLine], null)
        .then(newCart => {
            return <ICartActionResultWithCart> { cart: newCart, status: 'SUCCESS'};
        })
        .catch(error => {
            actionContext.telemetry.warning(error);
            actionContext.telemetry.debug('Unable to Update Cart Line');
            if(error.name === 'Microsoft_Dynamics_Commerce_Runtime_AddingItem_ExceedsMaximumQuantity'
                || error.name === 'Microsoft_Dynamics_Commerce_Runtime_UpdatingItem_ExceedsMaximumQuantity') {
                    return <ICartActionResultWithCart> {
                        cart: undefined,
                        status: 'FAILED',
                        substatus: 'QUANTITYLIMITS',
                        errorDetails: {
                            LocalizedMessage: error.message
                        }
                    };
            }

            return <ICartActionResultWithCart> { cart: undefined, status: 'FAILED'};
        });
}

async function updateCartLineQuantityValidateMaxAgainstSiteSettings(cart: Readonly<Cart | undefined>, cartLineId: string, newQuantity: number, actionContext: IActionContext
): Promise<ICartActionResultWithCart> {
    if (!cart || !cart.CartLines) {
        return { cart: undefined, status: 'FAILED'};
    }

    const matchingLines = cart.CartLines.filter(cartLine => cartLine.LineId === cartLineId);

    if (matchingLines.length === 0) {
        return { cart: undefined, status: 'FAILED'};
    }

    const copyCartLine: CartLine = {...matchingLines[0]};

    copyCartLine.Quantity = Math.min(newQuantity, actionContext.requestContext.app.config.maxQuantityForCartLineItem || 10);

    return updateCartLinesAsync({ callerContext: actionContext}, cart.Id, [copyCartLine], null)
        .then(newCart => {
            return <ICartActionResultWithCart> { cart: newCart, status: 'SUCCESS'};
        })
        .catch(error => {
            actionContext.telemetry.warning(error);
            actionContext.telemetry.debug('Unable to Update Cart Line');

            return <ICartActionResultWithCart> { cart: undefined, status: 'FAILED'};
        });
}