import { CacheType, createObservableDataAction, IAction, IActionContext, IActionInput, ICommerceApiSettings, ICreateActionContext } from '@msdyn365-commerce/core';
import { Cart } from '@msdyn365-commerce/retail-proxy';
import { copyAsync, readAsync } from '@msdyn365-commerce/retail-proxy/dist/DataActions/CartsDataActions.g';
import { getCartState } from './get-cart-state';

/**
 * Input class for getCheckoutCart data action
 */
export class GetCheckoutCartInput implements IActionInput {
    private apiSettings: ICommerceApiSettings;

    constructor(apiSettings: ICommerceApiSettings) {
        this.apiSettings = apiSettings;
    }

    public getCacheKey = () => `CheckoutCart-chanId:${this.apiSettings.channelId}-catId:${this.apiSettings.catalogId}`;
    public getCacheObjectType = () => 'CheckoutCart';
    public dataCacheType = (): CacheType => 'request';
}

const enum CartTokenPrefix {
    Auth = 't',
    Anon = 'p'
}

const createInput = (inputData: ICreateActionContext) => {
    return new GetCheckoutCartInput(inputData.requestContext.apiSettings);
};

/**
 * Data action to copy the cart for checkout actions
 */
export async function getCheckoutCart(input: GetCheckoutCartInput, ctx: IActionContext): Promise<Cart | undefined> {
    let savedCheckoutCartId = ctx.requestContext.cookies.getCheckoutCartCookie();
    const cartCookieParts: string[] = savedCheckoutCartId.split(':');
    let checkoutCartId = null;
    const isAuthenticated = ctx.requestContext.user.isAuthenticated;

    if ((isAuthenticated && cartCookieParts[0] === CartTokenPrefix.Auth) || (!isAuthenticated && cartCookieParts[0] === CartTokenPrefix.Anon)) {
        checkoutCartId = cartCookieParts[1];
    }

    let checkoutCart: Cart | undefined;

    if (checkoutCartId) {
        try {
            checkoutCart = await readAsync({ callerContext: ctx, bypassCache: 'none' }, checkoutCartId);
        } catch (error) {
            ctx.telemetry.error('Error getting checkout cart based on saved checkout card id');
            ctx.telemetry.exception(error);
        }
    }

    const cartState = await getCartState(ctx);
    if (cartState && cartState.cart.Id !== undefined) {
        if (checkoutCart && checkoutCart.Version && cartState.cart.Version && checkoutCart.Version > cartState.cart.Version) {
            return Promise.resolve(<Cart>checkoutCart);
        }

        return copyAsync({ callerContext: ctx }, cartState.cart.Id, 2)
            .then(cart => {
                ctx.requestContext.cookies.setCheckoutCartCookie(cart, isAuthenticated);
                return cart;
            })
            .catch(error => {
                ctx.telemetry.error('Error copying cart');
                ctx.telemetry.exception(error);
                return undefined;
            });
    }

    return undefined;
}

export const getCheckoutCartDataAction =  createObservableDataAction({
    id: '@msdyn365-commerce/global-state/get-checkout-cart',
    action: <IAction<Cart | undefined>>getCheckoutCart,
    input: createInput
});

export default getCheckoutCartDataAction;