// eslint-disable-next-line unicorn/filename-case
import * as React from 'react';

import { IActionToggleProps } from './ActionToggle.props';

/**
 * ActionToggle - This component is toggle button which switches between states
 * and for accessibility reasons are 2 different buttons.
 */
export default class ActionToggle extends React.PureComponent<IActionToggleProps> {
    private readonly ref: React.RefObject<HTMLDivElement> = React.createRef<HTMLDivElement>();

    private focusElement: HTMLElement | null = null;

    constructor(props: Readonly<IActionToggleProps>) {
        super(props);
        this._onClick = this._onClick.bind(this);
    }

    public getSnapshotBeforeUpdate(): null {
        this.focusElement = null;
        if (this.ref.current && this.ref.current.contains(document.activeElement)) {
            this.focusElement = document.activeElement as HTMLElement;
        }
        return null;
    }

    public componentDidUpdate(): void {
        if (this.ref.current && this.focusElement) {
            const focusTarget = this.ref.current.children[this.props.toggled ? 1 : 0] as HTMLElement;
            if (focusTarget !== document.activeElement) {
                focusTarget.focus();
            }
        }
    }

    public render(): JSX.Element | null {
        const { className, children, toggled, onChange, ...props } = this.props;
        const classes = `action-toggle${className ? ' ' : ''}${className || ''}`;
        return React.Children.count(children) !== 2 ? null : (
            <div className={classes} {...props} ref={this.ref}>
                {React.Children.map(children, (child: React.ReactNode, index: number) => {
                    const hidden = (!index && !!this.props.toggled) || (!!index && !this.props.toggled);
                    // tslint:disable-next-line:no-any
                    return React.cloneElement(child as any, { 'aria-hidden': hidden, onClick: this._onClick });
                })}
            </div>
        );
    }

    private _onClick(e: React.KeyboardEvent): void {
        e.stopPropagation();
        if (this.props.onChange) {
            this.props.onChange({ id: this.props.id, actionToggle: this, toggled: !!this.props.toggled, event: e });
        }
    }
}
